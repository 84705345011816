import React, { useState } from "react";
import "./Login.css";
import { Button, message } from "antd";
import { useNavigate } from "react-router-dom";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import newRequest from "../../utils/newRequest";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobalContextDispatch } from "../../context/GlobalContext";

export const Login = (props) => {
  const [forgetPassword, setForgetPassword] = useState("login");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [phoneNumer, setPhoneNumer] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loadings, setLoadings] = useState(false);
  const GlobalContextDispatch = useGlobalContextDispatch();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoadings(true);
      const requestData = {
        // phoneNumer: phoneNumer,
        email: email,
        password: password,
      };
      const res = await newRequest.post("/user/login", requestData);
      localStorage.setItem("user", JSON.stringify(res?.data?.data));
      setLoadings(false);
      queryClient.resetQueries();
      GlobalContextDispatch({
        type: "setUserData",
        payload: res?.data?.data,
      });
      GlobalContextDispatch({
        type: "setRoleTabs",
        payload: [],
      });
      navigate("/");
    } catch (error) {
      setLoadings(false);

      error
        ? error?.response?.data?.errors?.map(
            (error) => message.error(error?.message)
/*             message.error(error?.field + " " + error?.message)
 */          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-form">
          <form onSubmit={handleSubmit}>
            <img src="./askMeLogo.png" className="login-logo" alt="logo" />
            <p
              style={{ marginTop: "10px", fontSize: "20px", fontWeight: "600" }}
            >
              {forgetPassword === "login"
                ? "تسجيل الدخول"
                : "استرجاع كلمة المرور"}
            </p>
            {/* <input
              type="text"
              name="loginEmail"
              className="login-inputs"
              placeholder="رقم الجوال"
              style={{ marginTop: "30px", width: "100%" }}
              title="This field should not be left blank."
              required
              onChange={(e) => setPhoneNumer(e.target.value)}
              value={phoneNumer}
              onInvalid={(e) =>
                e.target.setCustomValidity("برجاء ادخال رقم الجوال")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            /> */}
            <input
              type="email"
              name="loginEmail"
              className="login-inputs"
              placeholder="البريد الالكترونى"
              style={{ marginTop: "30px", width: "100%" }}
              title="This field should not be left blank."
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              onInvalid={(e) =>
                e.target.setCustomValidity("برجاء ادخال البريد الالكترونى")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            />
            {forgetPassword === "login" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  position: "relative",
                  marginTop: "5px",
                }}
              >
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="كلمة المرور "
                  name="loginPassword"
                  className="login-inputs"
                  style={{ width: "100%" }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("برجاء ادخال كلمة المرور ")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                {passwordVisible ? (
                  <IoEye
                    onClick={togglePasswordVisibility}
                    className="login-password-eye-icon"
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={togglePasswordVisibility}
                    className="login-password-eye-icon"
                  />
                )}
              </div>
            ) : null}

            <p
              style={{
                textDecoration:
                  forgetPassword === "login" ? "underline" : "none",
                width: "100%",
                marginTop: "10px",
                fontSize: "18px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={() => {
                if (forgetPassword === "login") {
                  setForgetPassword("forget");
                }
              }}
            >
              {forgetPassword === "login"
                ? "نسيت كلمة المرور"
                : "لاسترجاع كلمة المرور قم بادخال البريد الالكترونى لارسال كود التفعيل عليه"}
            </p>
            <Button
              type="primary"
              loading={loadings}
              className="login-btn"
              htmlType="submit"
            >
              {forgetPassword === "login" ? "تسجيل الدخول" : "إرسال"}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
