import "./Settings.css";
import Avatr from "./../../assets/Avatr.png";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { IoEye } from "react-icons/io5";
import { IoMdEyeOff } from "react-icons/io";
import { Modal, Button, Select, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { RiImageAddLine } from "react-icons/ri";
import {
  getLoggedUserApi,
  editImageApi,
  editProfileApi,
} from "../../APIs/SettingsApis";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { url } from "../../APIs/MainApis";
import newRequest from "../../utils/newRequest";
import { message, Table, DatePicker } from "antd";
import { getCountriesApi, getCountryStatesApi } from "../../APIs/CountriesApis";
import { getPermissionsListApi, addRoleApi } from "../../APIs/RolesApis";
import {
  getAllUsersApi,
  deleteUserApi,
  getUserByIdApi,
  editUserByIdApi,
  createUserApi,
} from "../../APIs/UsersApis";
import Nationalities from "../../meta/nationalities.json";
import { getAllRolesApi } from "../../APIs/RolesApis";
import { UsePermission } from "../../Hooks/UsePermission";
import { getTermsApi, updateTermApi } from "../../APIs/TermsApis";
import { useGlobalContextState } from "../../context/GlobalContext";

const Settings = () => {
  const GlobalContextState = useGlobalContextState();
  const { hasPermission } = UsePermission();
  const [loggedUser, setLoggedUser] = useState({});
  const [activeLink, setActiveLink] = useState(0);
  const [profileImage, setProfileImage] = useState(Avatr);
  const [sentImage, setSentImage] = useState(null);
  const [selectedUserImage, setSelectedUserImage] = useState(Avatr);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [visible, setVisiblity] = useState(true);
  const [passFieldName, setPassFieldName] = useState("");
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [user, setUser] = useState({});
  const [updatedUser, setUpdatedUser] = useState({});
  const [confirmEditAccountOpen, setConfirmEditAccountOpen] = useState(false);
  const [confirmEditPassOpen, setConfirmEditPassOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [term, setTerm] = useState(false);

  /// edit password errors
  const [oldPassError, setOldPassError] = useState(false);
  const [newPassError, setNewPassError] = useState(false);
  const [confirmPassError, setConfirmPassError] = useState(false);
  ///edit pass toggle icon
  const [oldPassVisible, setOldPassVisible] = useState(true);
  const [newPassVisible, setNewPassVisible] = useState(true);
  const [confirmPassVisible, setConfirmPassVisible] = useState(true);
  // add role Modal
  const [addRole, setAddRoleOpen] = useState(false);
  const [roleName, setroleName] = useState(null);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesStates, setCountriesStates] = useState([]);
  const [permissionsList, setPermissionsList] = useState([]);

  /// add employee Modal
  const [empName, setEmpName] = useState(null);
  const [empEmail, setempEmail] = useState(null);
  const [addEmpPhoneNumber, setAddEmpPhoneNumber] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [gender, setgender] = useState("male");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [selectedRole, setselectedRole] = useState(null);
  const [rolesOptions, setrolesOptions] = useState();
  const [nationality, setnationality] = useState(null);

  // remove/edit user modals
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [editUserOpen, setEditUserOpen] = useState();
  const [userId, setUserId] = useState("");
  const [editEmpEmail, seteditEmpEmail] = useState(null);
  const [editEmpUserName, seteditEmpUserName] = useState(null);
  const [editEmpPhoneNumber, seteditEmpPhoneNumber] = useState();

  /// employees table
  const [addEmpOpen, setAddEmpOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  const columns = [
    // {
    //   dataIndex: "_id",
    //   key: "_id",
    //   title: "#",
    //   render: (text, record, index) => index + 1,
    //   className: "center-align",
    // },
    {
      title: <p style={{ fontSize: "18px", fontWeight: "700" }}>الموظفين</p>,
      dataIndex: "userName",
      //  render: (user) => user?.userName,
      className: "center-align",
    },
    {
      // title: "السؤال",
      dataIndex: "roleId",
      render: (roleId) => roleId?.key,
      className: "center-align",
    },

    {
      title: (
        <div className='flex justify-end items-center gap-2'>
          <button
            className='sett-add-emp-btn'
            onClick={() => setAddRoleOpen(true)}
          >
            {" "}
            اضافة صلاحية{" "}
          </button>
          <button
            className='sett-add-emp-btn'
            onClick={() => setAddEmpOpen(true)}
          >
            {" "}
            اضافة موظف{" "}
          </button>
        </div>
      ),
      key: "action",
      className: "center-align",
      render: (_, record, index) => (
        <div className='flex justify-end items-center '>
          <div className='tick-table-icons-div'>
            <span
              className='tick-table-delete'
              onClick={() => {
                setUserId(record?.id);
                setDeleteUserOpen(true);
              }}
            >
              <FaRegTrashAlt />
            </span>
          </div>
          <div className='tick-table-icons-div'>
            <span
              className='tick-table-delete'
              onClick={() => {
                setUserId(record?.id);
                setEditUserOpen(true);
              }}
            >
              <BiEditAlt />
            </span>
          </div>
        </div>
      ),
    },
  ];
  const navigate = useNavigate();

  useEffect(() => {
    //getToken()
    getLoggedUserFunc();
  }, []);
  const getToken = () => {
    // console.log(!localStorage.getItem("user"))
    if (!localStorage.getItem("user")) {
      navigate("/login");
    }
  };

  /// useEffects
  useEffect(() => {
    getAllUsersFunc();
  }, [page]);

  useEffect(() => {
    (async () => {
      const term = (await getTermsApi()).data.data;
      setTerm(term);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const allRoles = await getAllRolesApi();
      setrolesOptions(allRoles.data.data);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const countries = (await getCountriesApi()).data.data;
      const permissionsList = (await getPermissionsListApi()).data.data;
      setPermissionsList(Object.values(permissionsList).flat());
      setCountries(countries);
    })();
  }, [addRole]);

  /// toggle password fields icons
  const toggleOldPassVisibility = () => {
    setOldPassVisible(!oldPassVisible);
  };
  const toggleNewPassVisibility = () => {
    setNewPassVisible(!newPassVisible);
  };
  const toggleConfirmPassVisibility = () => {
    setConfirmPassVisible(!confirmPassVisible);
  };
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  /// edit account change handler
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => {
      return { ...prev, [name]: value };
    });
    switch (name) {
      case "userName":
        setUserName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "phoneNumber":
        setPhoneNumber(value);
        break;
      default:
        break;
    }
  };

  const filterObject = (obj) => {
    const filteredObj = {};
    for (const key in obj) {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        filteredObj[key] = obj[key];
      }
      setUpdatedUser(filteredObj);
    }
    return updatedUser;
  };

  ///get logged user func
  const getLoggedUserFunc = async () => {
    try {
      let response = await getLoggedUserApi();

      let data = await response?.data?.data;

      setLoggedUser(data);
      setProfileImage(data?.profileImage ? url + data.profileImage : Avatr);
      setSelectedUserImage(
        data?.profileImage ? url + data.profileImage : Avatr
      );
      setUserName(data?.userName);
      setEmail(data?.email);
      setPhoneNumber(data?.phoneNumber?.number);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  /// edit account func
  const editAccountFunc = async (e) => {
    setEditLoading(true);

    try {
      if (Object.keys(updatedUser).length) {
        const userResponse = await editProfileApi(updatedUser);
      }

      if (sentImage) {
        let updateImage = await editUserByIdApi(loggedUser.id, {
          profileImage: sentImage,
        });
      }
      message.success("تم تعديل الحساب بنجاح");
      setEditLoading(false);
      getLoggedUserFunc();
      setUpdatedUser({});
      setSentImage(null);
      setConfirmEditAccountOpen(false);
      //console.log(userResponse);
      // if (profileImage) {
      //   const formData = new FormData();
      //   formData.append("profileImage", profileImage);
      //   const imageResponse = await newRequest.put(
      //     "/user/update-profile",
      //     formData,
      //     {
      //       headers: {
      //         "Content-Type": "multipart/form-data",
      //       },
      //     }
      //   );
      //   console.log(imageResponse);
      //   message.success("تم التعديل بنجاح");
      // }
    } catch (error) {
      console.log(error);
      setEditLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors?.map((e) => {
        let error = e.field ? e.field + " " + e.message.ar : e.message.ar;
        errorsArr.push(error);
      });
      message.error(errors ? errorsArr.join() : "حدث خطأ ما");
    }
  };

  /// edit password func
  const editPassFunc = async (e) => {
    if (newPassword !== confirmPassword) {
      message.error({
        content: "كلمات المرور غير متطابقة",
      });
      return;
    }
    setEditLoading(true);
    try {
      const res = await newRequest.put("/user/change-password", {
        oldPassword,
        newPassword,
      });
      setEditLoading(false);
      setConfirmEditPassOpen(false);
      message.success(res?.data?.message);
      handleClearForm();
    } catch (error) {
      setEditLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field ? e.field + " " + e.message : e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  const handleClearForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
  };

  /// image on change
  const selectImageHandler = (event) => {
    const file = event.target.files[0];

    setSentImage(file);
    const reader = new FileReader();

    reader.onload = function (e) {
      setSelectedUserImage(e.target.result);
    };

    reader.readAsDataURL(file);
  };

  /// handle page change
  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };

  /// phone num change

  /// date picker change
  const formatDate = (input) => {
    let date = new Date(input);
    // Get day, month, and year from the date object
    let day = date.getDate();
    let month = date.getMonth() + 1; // Months are zero-based
    let year = date.getFullYear();

    // Ensure day and month are two digits
    day = day < 10 ? "0" + day : day;
    month = month < 10 ? "0" + month : month;

    // Return the formatted date string
    const newDateString = day + "/" + month + "/" + year;

    setDateOfBirth(newDateString);
  };
  /// image change
  const handleUpload = (e) => {
    try {
      //  setAddImageError(false);
      const file = e.target.files[0];

      setImageUploaded(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRole = async () => {
    await addRoleApi({
      key: roleName,
      feature: selectedPermissions,
      country: selectedCountries,
      state: selectedStates,
    });
    setroleName(null);
    setSelectedCountries([]);
    setSelectedStates([]);
    setSelectedPermissions([]);
    setAddRoleOpen(false);
    message.success("تمت إضافة الصلاحية بنجاح");
  };

  const handlePickRemovePermission = (permission) => {
    setSelectedPermissions(permission);
  };

  const handlePickRemoveCountry = async (countriesList) => {
    console.log(countriesList);
    setSelectedCountries(countriesList);
    let newStatesList = [];
    for (let i = 0; i < countriesList.length; i++) {
      const countryID = countriesList[i];
      const states = (await getCountryStatesApi(countryID)).data.data;
      newStatesList.push({ countryID, states });
    }
    console.log(newStatesList);
    setCountriesStates(newStatesList);
    // filter selected countries who don't exist in the newStates options
    const filteredStates = selectedStates.filter((state) => {
      return newStatesList.some((country) => {
        return country.states.some((s) => s._id === state);
      });
    });
    console.log("NEW STATES", newStatesList);
    console.log("SELECTED STATES", selectedStates);

    console.log("FILTERED", filteredStates);
    setSelectedStates(filteredStates);
  };

  const handlePickRemoveState = (statesList) => {
    setSelectedStates(statesList);
  };

  /// get all users func
  const getAllUsersFunc = async () => {
    setLoading(true);
    try {
      let res = await getAllUsersApi({
        page,
      });

      setUsers(res?.data?.data.filter((i) => i.roleId.key !== "free"));
      setLoading(false);
      const currentPage = res?.data?.pagination?.currentPage;
      const totalPages = res?.data?.pagination?.totalPages;
      const resultCount = res?.data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
    } catch (e) {
      setLoading(false);
    }
  };

  //delete user func
  const deleteUserFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteUserApi(userId);
      setLoading(false);
      setDeleteUserOpen(false);
      message.success("تم حذف الموظف بنجاح");
      getAllUsersFunc();
    } catch (error) {
      setLoading(false);
      console.log(error);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field ? e.field + " " + e.message : e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  const handleEditEmp = async () => {
    try {
      await editUserByIdApi(userId, {
        email: editEmpEmail,
        "phoneNumber[number]": editEmpPhoneNumber,
        userName: editEmpUserName,
      });
      message.success("تم التعديل بنجاح");
      setEditUserOpen(false);
    } catch (error) {
      message.error("حدث خطأ ما");
    }
  };

  const handleAddEmployee = async () => {
    try {
      await createUserApi({
        userName: empName,
        email: empEmail,
        "phoneNumber[key]": addEmpPhoneNumber[0],
        "phoneNumber[number]": addEmpPhoneNumber[1],
        password: password,
        nationality: nationality,
        gender: gender,
        birthDate: dateOfBirth,
        roleId: selectedRole,
        profileImage: imageUploaded,
      });
      message.success("تمت إضافة الموظف بنجاح");
      setAddEmpOpen(false);
    } catch (error) {
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field ? e.field + " " + e.message : e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  useEffect(() => {
    (async () => {
      if (editUserOpen) {
        const userData = (await getUserByIdApi(userId)).data.data;
        seteditEmpEmail(userData?.email ?? "");
        seteditEmpPhoneNumber(userData?.phoneNumber?.number ?? "");
        seteditEmpUserName(userData?.userName ?? "");
      }
    })();
  }, [editUserOpen, userId]);

  // hangle edit posts toggle
  const handleEditPostVisibilityChange = async (checked) => {
    try {
      await updateTermApi(term._id, { editPostVisibility: checked });
      setTerm({ ...term, editPostVisibility: checked });
    } catch (error) {
      message.error(error.message || "حدث خطأ ما");
    }
  };

  // handle edit paymentkey
  const handleEditPaymentKey = async () => {
    try {
      await updateTermApi(term._id, { paymentKey: term.moyasarKey });
      message.success("تم التعديل بنجاح");
    } catch (error) {
      message.error(error.message || "حدث خطأ ما");
    }
  };

  return (
    <>
      <div className='settings'>
        <div className='container'>
          <div className='header flex w-full justify-between items-center'>
            <div className='flex  items center'>
              <span style={{ color: "rgba(151, 151, 151, 1)" }}>
                لوحة التحكم{" "}
              </span>{" "}
              <span>/ الاعدادات</span>
            </div>
          </div>

          <div className='body'>
            <div className='flex gap-4'>
              <div className='info'>
                <img src={profileImage} alt='' />
                <div className='text-container'>
                  <span>
                    {loggedUser?.userName}
                    <span style={{ color: "#4578AF", margin: "0px 5px" }}>
                      {GlobalContextState.permissions.join(",").toUpperCase()}
                    </span>
                  </span>
                  <span>{loggedUser?.email}</span>
                </div>
              </div>

              {hasPermission("update term") && (
                <div className='w-[704px] h-[169px] bg-[white] flex flex-col justify-around items-start gap-4 p-6 rounded-[14px]'>
                  <div className='gap-2 flex items-center '>
                    <p>التعديل في التطبيق</p>

                    <Switch
                      checked={term.editPostVisibility}
                      onChange={handleEditPostVisibilityChange}
                      className=' p-3 w-fit rounded-[10px] h-fit aria-checked:bg-primary aria-checked:hover:!bg-primary '
                    />
                  </div>
                  <div className='flex flex-col gap-1'>
                    <p>مفتاح الدفع</p>
                    <div className='flex gap-2 items-center'>
                      <div className='form-control '>
                        <input
                          type='text'
                          placeholder='مفتاح الدفع'
                          minLength={4}
                          name='key'
                          onChange={(e) =>
                            setTerm({ ...term, moyasarKey: e.target.value })
                          }
                          value={term.moyasarKey}
                        />
                      </div>

                      <button
                        className='bg-[#4578af] px-8 py-2 text-white rounded-[8px]'
                        type='submit'
                        onClick={(e) => {
                          e.preventDefault();
                          handleEditPaymentKey();
                        }}
                      >
                        حفظ
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='sett-tables-div'>
              <div className='edit-info' style={{ marginTop: "20px" }}>
                <div className='wrapper'>
                  <div className='header'>
                    <ul className='list'>
                      <li
                        onClick={() => setActiveLink(0)}
                        className={activeLink === 0 ? " active" : ""}
                      >
                        تعديل حسابي
                      </li>
                      <li
                        onClick={() => setActiveLink(1)}
                        className={activeLink === 1 ? " active" : ""}
                      >
                        تغير كلمة المرور
                      </li>
                    </ul>
                  </div>
                  <div className='form-container'>
                    {/* Update User Info */}
                    {activeLink === 0 ? (
                      <form action='' className='form'>
                        {GlobalContextState?.permissions?.includes("admin") && (
                          <div className='form-control'>
                            <img src={selectedUserImage} alt='' />
                            <div className='actions'>
                              <p>الصورة الشخصية</p>
                              <label for='image'>تغير الصورة</label>
                              <input
                                type='file'
                                id='image'
                                onChange={selectImageHandler}
                              />
                              {/* {profileImage && (
                          <span className="image-name">
                            {profileImage?.name}
                          </span>
                        )} */}
                            </div>
                          </div>
                        )}

                        <div className='form-control'>
                          <input
                            type='text'
                            placeholder='اسم المستخدم'
                            minLength={4}
                            name='userName'
                            // onChange={(e) => setUserName(e.target.value)}
                            value={userName}
                            onChange={handleChange}
                          />
                        </div>

                        <div className='form-control'>
                          <input
                            type='email'
                            placeholder='البريد الالكتروني'
                            name='email'
                            value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            onChange={handleChange}
                          />
                        </div>

                        <div className='form-control'>
                          <input
                            type='text'
                            placeholder='رقم الجوال'
                            title='رقم الجوال ارقام فقط'
                            pattern='[0-9]*'
                            name='phoneNumber'
                            value={phoneNumber}
                            // onChange={(e) => setPhoneNumber(e.target.value)}
                            onChange={handleChange}
                          />
                        </div>

                        <div className='form-actions'>
                          <button
                            type='submit'
                            onClick={(e) => {
                              e.preventDefault();
                              setUser("");
                              setConfirmEditAccountOpen(true);
                              filterObject(user);
                            }}
                          >
                            حفظ التعديلات
                          </button>
                          <button
                            type='button'
                            onClick={() => {
                              setUser("");
                            }}
                          >
                            الغاء
                          </button>
                        </div>
                      </form>
                    ) : (
                      <form
                        action=''
                        className='form'
                        //onSubmit={editPassFunc}
                      >
                        <div
                          className='form-control'
                          onClick={() => console.log("aaa")}
                        >
                          <input
                            required
                            minLength={8}
                            type={!oldPassVisible ? "text" : "password"}
                            placeholder='كلمة المرور القديمة'
                            name='password'
                            onChange={(e) => {
                              setOldPassword(e.target.value);
                              if (e.target.value) {
                                setOldPassError(false);
                              }
                            }}
                            value={oldPassword}
                          />
                          {oldPassVisible ? (
                            <AiOutlineEyeInvisible
                              className='second-icon'
                              onClick={toggleOldPassVisibility}
                            />
                          ) : (
                            <AiOutlineEye
                              className='second-icon'
                              onClick={toggleOldPassVisibility}
                            />
                          )}
                        </div>
                        {oldPassError && !oldPassword ? (
                          <p style={{ color: "red" }}>
                            من فضلك ادخل كلمة المرور القديمة
                          </p>
                        ) : null}
                        <div className='form-control'>
                          <input
                            required
                            minLength={8}
                            type={!newPassVisible ? "text" : "password"}
                            placeholder='كلمة المرور الجديدة'
                            name='password'
                            value={newPassword}
                            onChange={(e) => {
                              setNewPassword(e.target.value);
                              if (e.target.value) {
                                setNewPassError(false);
                              }
                            }}
                          />
                          {newPassVisible ? (
                            <AiOutlineEyeInvisible
                              className='second-icon'
                              onClick={toggleNewPassVisibility}
                            />
                          ) : (
                            <AiOutlineEye
                              className='second-icon'
                              onClick={toggleNewPassVisibility}
                            />
                          )}
                        </div>
                        {newPassError && !newPassword ? (
                          <p style={{ color: "red" }}>
                            من فضلك ادخل كلمة المرور الجديدة
                          </p>
                        ) : null}
                        <div className='form-control'>
                          <input
                            required
                            minLength={8}
                            type={!confirmPassVisible ? "text" : "password"}
                            placeholder=' تاكيد كلمة المرور الجديدة'
                            name='password'
                            value={confirmPassword}
                            onChange={(e) => {
                              setConfirmPassword(e.target.value);
                              if (e.target.value) {
                                setConfirmPassError(false);
                              }
                            }}
                          />
                          {confirmPassVisible ? (
                            <AiOutlineEyeInvisible
                              className='second-icon'
                              onClick={toggleConfirmPassVisibility}
                            />
                          ) : (
                            <AiOutlineEye
                              className='second-icon'
                              onClick={toggleConfirmPassVisibility}
                            />
                          )}
                        </div>
                        {confirmPassError && !confirmPassword ? (
                          <p style={{ color: "red" }}>
                            من فضلك أعد ادخال كلمة المرور الجديدة
                          </p>
                        ) : null}
                        <div className='form-actions'>
                          <button
                            type='submit'
                            onClick={(e) => {
                              e.preventDefault();
                              if (
                                !oldPassword ||
                                !newPassword ||
                                !confirmPassword
                              ) {
                                setOldPassError(true);
                                setNewPassError(true);
                                setConfirmPassError(true);
                              } else {
                                setConfirmEditPassOpen(true);
                              }
                            }}
                          >
                            حفظ التعديلات
                          </button>
                          <button onClick={handleClearForm} type='button'>
                            الغاء
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
              <div className='tick-table'>
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={users}
                  pagination={pagination}
                  onChange={handleTableChange}
                  className='sett-emp-table'
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/***** add new section******/}
      <Modal
        title='إضافة موظف'
        className='sec-add-section-modal sett-add-emp-modal'
        open={addEmpOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddEmpOpen(false);
        }}
        footer={null}
      >
        <div className='sec-add-sec-modal-body'>
          <div style={{ position: "relative" }}>
            {/* {imageUrl ? ( */}
            <img
              src={imageUrl}
              alt=''
              className='intro-add-modal-img z-40 relative pointer-events-none object-cover'
            />
            {/* ) : ( */}
            <div style={{ position: "absolute", top: "0", left: "0" }}>
              <label htmlFor='uploadInput'>
                <div className='intro-add-modal-avatar'>
                  <input
                    type='file'
                    id='uploadInput'
                    accept='image/*'
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <RiImageAddLine
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "gray",
                    }}
                  />
                </div>
              </label>
            </div>
            {/* )} */}
          </div>
          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='إسم الموظف '
            style={{ marginTop: "15px", minHeight: "45px" }}
            value={empName}
            onChange={(e) => {
              setEmpName(e.target.value);
            }}
          />
          <input
            type='email'
            className='sec-add-sec-modal-input'
            placeholder='البريد الالكتروني '
            style={{ marginTop: "15px", minHeight: "45px" }}
            value={empEmail}
            onChange={(e) => {
              setempEmail(e.target.value);
            }}
          />

          <PhoneInput
            country={"sa"} // Default country is set to Saudi Arabia (SA)
            onChange={(value, data) => {
              console.log(value, data);

              setAddEmpPhoneNumber([
                data.dialCode,
                value.replace(data.dialCode, ""),
              ]);
            }}
            inputProps={{
              name: "phone",
              required: true,
              autoFocus: true,
            }}
            inputStyle={{
              width: "100%",
              // height: "58px",
              position: "relative",

              boxSizing: "border-box",
              // paddingTop: "1.8em ",
              // paddingBottom: "1.8em ",
              // paddingLeft:"2.5em",
              // textIndent: "1em",
              border: "none",
              outline: "none",
              borderRadius: "7px",
              backgroundColor: "rgba(245, 245, 245, 1)",
            }}
          />
          <DatePicker onChange={formatDate} />

          <Select
            placeholder={"الجنس"}
            name='gender'
            className='sett-select-menu'
            options={[
              { label: "ذكر", value: "male" },
              { label: "أنثى", value: "female" },
            ]}
            style={{ marginTop: "15px" }}
            onChange={(val) => setgender(val)}
            value={gender}
          />
          <Select
            placeholder={"الجنسية"}
            name='الجنسية'
            className='sett-select-menu'
            style={{ marginTop: "15px" }}
            options={Nationalities}
            onChange={(val) => setnationality(val)}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              position: "relative",
              marginTop: "5px",
            }}
          >
            <input
              type={passwordVisible ? "text" : "password"}
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
              placeholder='كلمة المرور '
              name='loginPassword'
              className='login-inputs'
              style={{ width: "100%" }}
              onInvalid={(e) =>
                e.target.setCustomValidity("برجاء ادخال كلمة المرور ")
              }
            />
            {passwordVisible ? (
              <IoEye
                onClick={togglePasswordVisibility}
                className='login-password-eye-icon'
              />
            ) : (
              <IoMdEyeOff
                onClick={togglePasswordVisibility}
                className='login-password-eye-icon'
              />
            )}
          </div>
          <Select
            placeholder={"الصلاحيات"}
            name='permissions'
            className='sett-select-menu'
            style={{ marginTop: "15px" }}
            value={selectedRole}
            onChange={(value) => setselectedRole(value)}
          >
            {rolesOptions?.map((role) => (
              <Select.Option key={role.id} value={role.id}>
                {role.key}
              </Select.Option>
            ))}
          </Select>
          <Button
            className='sec-add-sec-modal-btn'
            onClick={() => handleAddEmployee()}
          >
            إضافة
          </Button>
        </div>
      </Modal>
      {/* add new role */}
      <Modal
        className='rules-add-rules-modal'
        open={addRole}
        onOk={() => {}}
        onCancel={() => {
          setAddRoleOpen(false);
        }}
        footer={null}
        closable={false}
      >
        <div className='rules-add-rules-modal-body'>
          <p className='text-xl'>اضافه صلاحيه </p>

          <div className='my-10 w-full'>
            <div className='form-control'>
              <input
                type='text'
                placeholder=' اسم الصلاحيه '
                minLength={4}
                name='count'
                onChange={(e) => setroleName(e.target.value)}
                value={roleName}
              />
            </div>
            <Select
              placeholder={"الدولة"}
              name='countries'
              className='sett-select-menu'
              style={{ marginTop: "15px" }}
              mode='multiple'
              value={selectedCountries}
              onChange={(value) => handlePickRemoveCountry(value)}
            >
              {countries.map((country) => (
                <Select.Option key={country._id} value={country._id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
            <Select
              placeholder={"المدينة"}
              name='countries'
              className='sett-select-menu'
              style={{ marginTop: "15px" }}
              mode='multiple'
              value={selectedStates}
              onChange={(value) => handlePickRemoveState(value)}
            >
              {countriesStates?.map((group) => (
                <>
                  {group?.states?.map((state) => (
                    <Select.Option key={state._id} value={state._id}>
                      {state.stateName}
                    </Select.Option>
                  ))}
                </>
              ))}
            </Select>
            <Select
              placeholder={"الصلاحيات"}
              name='permissions'
              className='sett-select-menu'
              style={{ marginTop: "15px" }}
              mode='multiple'
              value={selectedPermissions}
              onChange={(value) => handlePickRemovePermission(value)}
            >
              {permissionsList.map((permission) => (
                <Select.Option key={permission} value={permission}>
                  {permission}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className='rules-edit-rules-modal-btns'>
            <Button
              disabled={
                !roleName ||
                !selectedCountries.length === 0 ||
                permissionsList.length === 0
              }
              className='rules-edit-rules-modal-okBtn disabled:!bg-gray-400 disabled:hover:!bg-gray-400 disabled:!cursor-auto'
              onClick={() => handleAddRole()}
            >
              إضافة
            </Button>
            <Button
              className='rules-edit-rules-modal-cancelBtn'
              onClick={() => {
                setAddRoleOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/* edit emp modal */}
      <Modal
        className='rules-add-rules-modal'
        open={editUserOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditUserOpen(false);
        }}
        footer={null}
        closable={false}
      >
        <div className='rules-add-rules-modal-body'>
          <p className='text-xl'>تعديل موظف </p>

          <div className='my-10 flex flex-col gap-3 w-full'>
            <div className='form-control'>
              <input
                type='text'
                placeholder='اسم المستخدم'
                minLength={4}
                name='userName'
                onChange={(e) => seteditEmpUserName(e.target.value)}
                value={editEmpUserName}
              />
            </div>

            <div className='form-control'>
              <input
                type='email'
                placeholder='البريد الالكتروني'
                name='email'
                value={editEmpEmail}
                onChange={(e) => seteditEmpEmail(e.target.value)}
              />
            </div>

            <div className='form-control'>
              <input
                type='text'
                placeholder='رقم الجوال'
                title='رقم الجوال ارقام فقط'
                pattern='[0-9]*'
                name='phoneNumber'
                value={editEmpPhoneNumber}
                onChange={(e) => seteditEmpEmail(e.target.value)}
              />
            </div>
          </div>

          <div className='rules-edit-rules-modal-btns'>
            <Button
              disabled={
                !editEmpEmail || !editEmpPhoneNumber || !editEmpUserName
              }
              className='rules-edit-rules-modal-okBtn disabled:!bg-gray-400 disabled:hover:!bg-gray-400 disabled:!cursor-auto'
              onClick={() => handleEditEmp()}
            >
              تعديل
            </Button>
            <Button
              className='rules-edit-rules-modal-cancelBtn'
              onClick={() => {
                setEditUserOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***confirm edit account*** */}
      <ConfirmationModal
        title='تأكيد تعديل المستخدم'
        open={confirmEditAccountOpen}
        loading={editLoading}
        onCancel={() => setConfirmEditAccountOpen(false)}
        onClick={editAccountFunc}
      />
      {/***confirm edit pass*** */}
      <ConfirmationModal
        title='تأكيد تعديل كلمة المرور'
        open={confirmEditPassOpen}
        loading={editLoading}
        onCancel={() => setConfirmEditPassOpen(false)}
        onClick={editPassFunc}
      />
      <ConfirmationModal
        title='تأكيد حذف الموظف'
        open={deleteUserOpen}
        loading={loading}
        onCancel={() => setDeleteUserOpen(false)}
        onClick={deleteUserFunc}
      />
    </>
  );
};

export default Settings;
