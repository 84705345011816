import { Button, Col, Modal, Row } from "antd";
import React, { useState } from "react";
import { BiEditAlt } from "react-icons/bi";
import { FaRegTrashAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { useFetchData, usePostData } from "../../Hooks/useAxios";
import { ADMIN } from "../../utils/Queries";
import "./InappropriateWords.css";

export const InappropriateWords = () => {
  const {
    data: badWords,
    error,
    isPending,
    refetch,
  } = useFetchData({
    identifier: ADMIN.GET_INAPPROPRIATE_WORDS,
    api: process.env.REACT_APP_INAPPROPRIATE_WORDS,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    select: true,
  });
  const [ToggleAddNewWordModal, setToggleAddNewWordModal] = useState(false);

  const [newWord, setNewWord] = useState("");
  const [newWordError, setNewWordError] = useState(false);
  const [selectedWord, setSelectedWord] = useState("");
  const [toggleDeleteWordModal, setToggleDeleteWordModal] = useState(false);

  const { mutate: AddWord, isPending: AddWordIsPending } = usePostData(
    true,
    () => {
      setToggleAddNewWordModal(false);
      setNewWord("");
      refetch();
    }
  );
  const { mutate: DeleteWord, isPending: DeleteWordIsPending } = usePostData(
    true,
    () => {
      setToggleDeleteWordModal(false);
      refetch();
    }
  );
  const AddNewWordFunc = () => {
    if (newWord === "") {
      setNewWordError(true);
    } else {
      AddWord({
        api: process.env.REACT_APP_INAPPROPRIATE_WORDS,
        data: { words: [newWord] },
      });
    }
  };

  const deleteWordFunc = () => {
    DeleteWord({
      api: process.env.REACT_APP_INAPPROPRIATE_WORDS,
      data: { words: [selectedWord] },
      method: "DELETE",
    });
  };

  return (
    <>
      <div
        className="sec-main"
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="sec-top-txt">
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الألفاظ الغير لائقة</span>
          </p>
          <button
            className="sec-add-sec-btn"
            onClick={() => {
              setToggleAddNewWordModal(true);
            }}
          >
            اضافه لفظ غير لائق
          </button>
        </div>
        <div className="sec-table">
          <div style={{ minWidth: "500px" }}>
            <Row className="sec-table-head-row">
              <Col xs={2} style={{ fontSize: "20px", fontWeight: "600" }}>
                #
              </Col>
              <Col xs={16} style={{ fontSize: "20px", fontWeight: "600" }}>
                اللفظ
              </Col>
              <Col xs={6}></Col>
            </Row>
          </div>
          <div
            style={{ minWidth: "500px", maxHeight: "400px", overflow: "auto" }}
          >
            {badWords?.words?.length === 0 ? (
              <>
                <p className="text-center py-5">لا توجد الفاظ غير لائقة</p>
              </>
            ) : (
              badWords?.words?.map((word, index) => (
                <Row className="sec-table-row">
                  <Col xs={2} className="sec-table-col">
                    {index + 1}
                  </Col>
                  <Col xs={16} className="sec-table-col">
                    {word}
                  </Col>
                  <Col xs={6}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span
                        className="sec-table-delete"
                        onClick={() => {
                          setSelectedWord(word);
                          setToggleDeleteWordModal(true);
                        }}
                      >
                        <FaRegTrashAlt style={{ fontSize: "18px" }} />
                      </span>
                    </div>
                  </Col>
                </Row>
              ))
            )}
          </div>
        </div>
      </div>
      {/***** add new section******/}
      <Modal
        title="إضافة لفظ غير لائق جديد"
        className="sec-add-section-modal"
        open={ToggleAddNewWordModal}
        onOk={() => {}}
        onCancel={() => {
          setToggleAddNewWordModal(false);
          setNewWord("");
        }}
        footer={null}
      >
        <div className="sec-add-sec-modal-body">
          <input
            type="text"
            className="sec-add-sec-modal-input"
            placeholder="إضافة لفظ غير لائق"
            value={newWord}
            onChange={(e) => {
              setNewWord(e.target.value);
              if (e.target.value !== "") {
                setNewWordError(false);
              }
            }}
          />
          {newWordError && (
            <p style={{ color: "red" }}>من فضلك ادخل اسم اللفظ الغير لائق</p>
          )}
          <Button
            className="sec-add-sec-modal-btn"
            onClick={AddNewWordFunc}
            loading={AddWordIsPending}
          >
            إضافة
          </Button>
        </div>
      </Modal>

      <ConfirmationModal
        title="تأكيد حذف اللفظ الغير لائق"
        open={toggleDeleteWordModal}
        onClick={deleteWordFunc}
        onCancel={() => {
          setToggleDeleteWordModal(false);
        }}
        loading={DeleteWordIsPending}
      />
    </>
  );
};
