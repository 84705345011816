import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, message, Button } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { RiImageAddLine } from "react-icons/ri";
import {
  getNicknameApi,
  getNicknamesApi,
  addNickNameApi,
  updateNicknameApi,
  deleteNicknameApi,
} from "../../APIs/NickNamesApis";

import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";

const NickNames = () => {
  const [addNewNicknameOpen, setAddNewNicknameOpen] = useState(false);
  const [editNicknameOpen, setEditNicknameOpen] = useState(false);
  const [confirmEditNicknameOpen, setConfirmEditNicknameOpen] = useState(false);
  const [confirmAddNicknameOpen, setConfirmAddNicknameOpen] = useState(false);
  const [confirmDeleteNicknameOpen, setConfirmDeleteNicknameOpen] =
    useState(false);
  const [addNicknameError, setAddNicknameError] = useState(false);
  const [editNickname, setEditNickname] = useState("");

  const [nicknameId, setNicknameId] = useState("");

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  // add nickname fields
  const [addNicknameName, setaddNicknameName] = useState();
  const [addNicknameActions, setaddNicknameActions] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(null);

  /// useEffects
  useEffect(() => {
    getNicknamesFunc();
  }, []);

  // get Categories func
  const getNicknamesFunc = async () => {
    try {
      let response = await getNicknamesApi();

      let data = await response?.data?.data;
      setCategories([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  // delete nickname Func
  const deleteNicknameFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteNicknameApi(nicknameId);
      setLoading(false);
      setConfirmDeleteNicknameOpen(false);
      getNicknamesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + " " + e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  const confirmEditNicknameFunc = async () => {
    setLoading(true);
    try {
      let response = await updateNicknameApi(nicknameId, {
        name: editNickname.name,
        actionCount: editNickname.actionCount,
        image: imageUploaded ?? editNickname.image ?? null,
      });

      setLoading(false);
      setConfirmEditNicknameOpen(false);
      setEditNicknameOpen(false);
      setaddNicknameName("");
      setaddNicknameActions("");
      setImageUploaded(null);
      setImageUrl(null);
      message.success(response?.data?.message);
      getNicknamesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + " " + e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  /// image change
  const handleUpload = (e) => {
    try {
      //  setAddImageError(false);
      const file = e.target.files[0];

      setImageUploaded(file);
      const reader = new FileReader();

      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.log(error);
    }
  };

  // add nickname func
  const handleAddNickName = async () => {
    try {
      await addNickNameApi({
        name: addNicknameName,
        actionCount: addNicknameActions,
        image: imageUploaded,
      });
      setConfirmAddNicknameOpen(false);
      setAddNewNicknameOpen(false);
      setaddNicknameName("");
      setaddNicknameActions("");
      setImageUploaded(null);
      setImageUrl(null);
      message.success("تم إضافة اللقب بنجاح");
      getNicknamesFunc();
    } catch (error) {
      message.error("حدث خطأ ما");
    }
  };

  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='sec-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الألقاب</span>
          </p>
          <button
            className='sec-add-sec-btn'
            onClick={() => {
              setAddNewNicknameOpen(true);
            }}
          >
            إضافة لقب جديد
          </button>
        </div>
        <div className='sec-table'>
          <div style={{ minWidth: "500px" }}>
            <Row className='sec-table-head-row'>
              <Col xs={2} style={{ fontSize: "20px", fontWeight: "600" }}>
                #
              </Col>
              <Col xs={6} style={{ fontSize: "20px", fontWeight: "600" }}>
                اسم اللقب
              </Col>
              <Col xs={2} style={{ fontSize: "20px", fontWeight: "600" }}>
                عدد الأكشنز
              </Col>
            </Row>
          </div>
          <div
            style={{ minWidth: "500px", maxHeight: "400px", overflow: "auto" }}
          >
            {categories?.map((cat, index) => (
              <Row className='sec-table-row'>
                <Col xs={2} className='sec-table-col'>
                  {index + 1}
                </Col>
                <Col xs={6} className='sec-table-col'>
                  {cat?.name}
                </Col>
                <Col xs={6} className='sec-table-col'>
                  {cat?.actionCount}
                </Col>

                <Col xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className='sec-table-edit'
                      onClick={() => {
                        setEditNickname(cat);
                        setEditNicknameOpen(true);
                        setNicknameId(cat?._id);
                      }}
                    >
                      <BiEditAlt style={{ fontSize: "20px" }} />
                    </span>
                    <span
                      className='sec-table-delete'
                      onClick={() => {
                        setNicknameId(cat?._id);
                        setConfirmDeleteNicknameOpen(true);
                      }}
                    >
                      <FaRegTrashAlt style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
      {/***** add new nickname******/}
      <Modal
        title='إضافة لقب جديد'
        className='sec-add-nickname-modal'
        open={addNewNicknameOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddNewNicknameOpen(false);
          setaddNicknameName("");
          setaddNicknameActions("");
        }}
        footer={null}
      >
        <div className='sec-add-sec-modal-body flex flex-col gap-4 w-full'>
          <div style={{ position: "relative" }} className='mb-8'>
            {/* {imageUrl ? ( */}
            <img
              src={imageUrl}
              alt=''
              className='intro-add-modal-img z-40 relative pointer-events-none object-cover'
            />
            {/* ) : ( */}
            <div style={{ position: "absolute", top: "0", left: "0" }}>
              <label htmlFor='uploadInput'>
                <div className='intro-add-modal-avatar'>
                  <input
                    type='file'
                    id='uploadInput'
                    accept='image/*'
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <RiImageAddLine
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "gray",
                    }}
                  />
                </div>
              </label>
            </div>
            {/* )} */}
          </div>
          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='إسم اللقب'
            value={addNicknameName}
            onChange={(e) => {
              setaddNicknameName(e.target.value);
              if (e.target.value !== "") {
                setAddNicknameError(false);
              }
            }}
          />
          {addNicknameError && (
            <p style={{ color: "red" }}>من فضلك ادخل اسم اللقب</p>
          )}
          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='عدد الأكشنز'
            value={addNicknameActions}
            onChange={(e) => {
              setaddNicknameActions(e.target.value);
            }}
          />
          <Button
            className='sec-add-sec-modal-btn'
            onClick={() => setConfirmAddNicknameOpen(true)}
            // loading={loading}
          >
            إضافة
          </Button>
        </div>
      </Modal>

      {/***** edit nickname******/}
      <Modal
        title='تعديل اللقب  '
        className='sec-add-nickname-modal'
        open={editNicknameOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditNicknameOpen(false);
          setEditNickname(null);
          setaddNicknameName("");
          setaddNicknameActions("");
          setImageUrl(null);
          setImageUploaded(null);
        }}
        footer={null}
      >
        <div className='sec-add-sec-modal-body flex flex-col gap-4 w-full'>
          <div style={{ position: "relative" }} className='mb-8'>
            {/* {imageUrl ? ( */}
            <img
              src={
                imageUrl ??
                process.env.REACT_APP_ASSETS_BASE_URL + editNickname?.image ??
                ""
              }
              alt=''
              className='intro-add-modal-img z-40 relative pointer-events-none object-cover'
            />
            {/* ) : ( */}
            <div style={{ position: "absolute", top: "0", left: "0" }}>
              <label htmlFor='uploadInput'>
                <div className='intro-add-modal-avatar'>
                  <input
                    type='file'
                    id='uploadInput'
                    accept='image/*'
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <RiImageAddLine
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "gray",
                    }}
                  />
                </div>
              </label>
            </div>
            {/* )} */}
          </div>
          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='إسم اللقب'
            value={editNickname?.name}
            onChange={(e) => {
              setaddNicknameName(e.target.value);
              setEditNickname({ ...editNickname, name: e.target.value });
            }}
          />

          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='عدد الأكشنز'
            value={editNickname?.actionCount}
            onChange={(e) => {
              setEditNickname({ ...editNickname, actionCount: e.target.value });
            }}
          />
          <Button
            className='sec-add-sec-modal-btn'
            onClick={() => setConfirmEditNicknameOpen(true)}
            // loading={loading}
          >
            تعديل
          </Button>
        </div>
      </Modal>
      {/***** confirm edit nickname******/}
      <ConfirmationModal
        title='تأكيد تعديل اللقب'
        open={confirmEditNicknameOpen}
        onClick={confirmEditNicknameFunc}
        onCancel={() => setConfirmEditNicknameOpen(false)}
        loading={loading}
      />
      {/***confirm add nickname** */}
      <ConfirmationModal
        title='تأكيد إضافة اللقب'
        open={confirmAddNicknameOpen}
        onClick={handleAddNickName}
        onCancel={() => {
          setAddNewNicknameOpen(false);
          setConfirmAddNicknameOpen(false);
          setaddNicknameName("");
          setaddNicknameActions("");
          setImageUploaded(false);
          setImageUrl("");
        }}
        loading={loading}
      />
      {/***confirm delete nickname** */}
      <ConfirmationModal
        title='تأكيد حذف اللقب'
        open={confirmDeleteNicknameOpen}
        onClick={deleteNicknameFunc}
        onCancel={() => {
          setConfirmDeleteNicknameOpen(false);
        }}
        loading={loading}
      />
    </>
  );
};
export default NickNames;
