import newRequest from "../utils/newRequest";

//url
export const url = process.env.REACT_APP_ENDPOINTS_BASE_URL;
export const assetsURL = process.env.REACT_APP_ASSETS_BASE_URL;
// get users data
export const getAllUsersApi = () => {
  let result = newRequest.get("/user/users");
  return result;
};

// get reports
export const getAllReportsApi = (type) => {
  let result = newRequest.get(`/report?status=pending&type=${type}`);
  return result;
};
