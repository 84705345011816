import { GoHome } from "react-icons/go";
import { AiOutlineUser } from "react-icons/ai";
import { BsFiles } from "react-icons/bs";
import { RiBillLine } from "react-icons/ri";
import { BsTicket } from "react-icons/bs";
import { PiWarningOctagon } from "react-icons/pi";
import { MdOutlineImageAspectRatio } from "react-icons/md";
import { IoImageOutline } from "react-icons/io5";
import { CiLock } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { GoQuestion } from "react-icons/go";
import { IoMdGlobe } from "react-icons/io";
import { GoRocket } from "react-icons/go";
import { MdOutlineMailOutline } from "react-icons/md";
import { MdOutlineGroups } from "react-icons/md";
import { CiMoneyCheck1 } from "react-icons/ci";
import { IoIosNotificationsOutline } from "react-icons/io";
import { LuLayoutGrid } from "react-icons/lu";

import { IoSettingsOutline } from "react-icons/io5";
export const sidebarMenuItems = [
  {
    key: "1",
    label: "الرئيسية",
    icon: <GoHome className='sidebar_icon' />,
    path: "/main",
    permission: "main",
  },
  {
    key: "2",
    label: "المستخدمين",
    icon: <AiOutlineUser className='sidebar_icon' />,
    path: "/users",
    permission: "user",
  },
  {
    key: "3",
    label: "الأسئلة المثبتة",
    icon: <GoQuestion className='sidebar_icon' />,
    path: "/questions",
    permission: "post",
  },
  {
    key: "3.5",
    label: "الألفاظ الغير لائقة",
    icon: <GoQuestion className='sidebar_icon' />,
    path: "/bad-words",
    permission: "bad-words",
  },
  {
    key: "4",
    label: "الأقسام",
    icon: <BsFiles className='sidebar_icon' />,
    path: "/sections",
    permission: "category",
  },
  {
    key: "4.1",
    label: "الألقاب",
    icon: <AiOutlineUser className='sidebar_icon' />,
    path: "/nicknames",
    permission: "nickname",
  },
  {
    key: "4.2",
    label: "الصواريخ",
    icon: <GoRocket className='sidebar_icon' />,
    path: "/rocket",
    permission: "terms",
  },
  {
    key: "5",
    label: "الباقات",
    icon: <CiMoneyCheck1 className='sidebar_icon' />,
    path: "/packages",
    permission: "plan",
  },
  {
    key: "6",
    label: "الفواتير",
    icon: <RiBillLine className='sidebar_icon' />,
    path: "/invoice",
    permission: "invoice",
  },
  {
    key: "7",
    label: "التذاكر",
    icon: <BsTicket className='sidebar_icon' />,
    path: "/ticket",
    permission: "ticket",
  },
  {
    key: "8",
    label: "البلاغات",
    icon: <PiWarningOctagon className='sidebar_icon' />,
    path: "/reports",
    permission: "report",
  },
  {
    key: "8.5",
    label: "hr",
  },
  {
    key: "9",
    label: "البنر الرئيسى",
    icon: <MdOutlineImageAspectRatio className='sidebar_icon' />,
    path: "/banner",
    permission: "board",
  },
  {
    key: "10",
    label: "التعريف بالتطبيق",
    icon: <IoImageOutline className='sidebar_icon' />,
    path: "/intro",
    permission: "slider",
  },

  {
    key: "11",
    label: "سياسة الخصوصية",
    icon: <CiLock className='sidebar_icon' />,
    path: "/privacy",
    permission: "term",
  },
  {
    key: "11.1",
    label: "المميزات",
    icon: <LuLayoutGrid className='sidebar_icon' />,
    path: "/features",
    permission: "term",
  },

  // {
  //   key: "11.2",
  //   label: "الشروط والأحكام",
  //   icon: <CiLock className='sidebar_icon' />,
  //   path: "/terms-and-conditions",
  //   permission: "term",
  // },
  {
    key: "11.3",
    label: "إرشادات المجتمع",
    icon: <MdOutlineGroups className='sidebar_icon' />,
    path: "/guidlines",
    permission: "base",
  },
  {
    key: "11.5",
    label: "الدول والمدن",
    icon: <IoMdGlobe className='sidebar_icon' />,
    path: "/countries",
    permission: "country",
  },
  {
    key: "12",
    label: "القواعد",
    icon: <RxHamburgerMenu className='sidebar_icon' />,
    path: "/rules",
    permission: "base",
  },
  {
    key: "12.5",
    label: "hr",
  },
  {
    key: "12.6",
    label: "إرسال بريد إلكتروني",
    icon: <MdOutlineMailOutline className='sidebar_icon' />,
    path: "/email",
    permission: "sendEmail",
  },
  {
    key: "12.7",
    label: "إرسال إشعار ",
    icon: <IoIosNotificationsOutline className='sidebar_icon' />,
    path: "/notify",
    permission: "send notification",
  },

  {
    key: "13",
    label: "الإعدادات",
    icon: <IoSettingsOutline className='sidebar_icon' />,
    path: "/settings",
    permission: "settings",
  },
];
