import newRequest from "../utils/newRequest";

/// add nickname
export const addNickNameApi = (data) => {
  const formData = new FormData();

  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = newRequest.post("/nickname", formData);
  return result;
};

// get nickname
export const getNicknameApi = (id) => {
  let result = newRequest.get(`/nickname/${id}`);
  return result;
};

/// get nicknames
export const getNicknamesApi = () => {
  let result = newRequest.get("/nickname");
  return result;
};

/// delete nickname
export const deleteNicknameApi = (id) => {
  let result = newRequest.delete(`/nickname/${id}`);
  return result;
};

/// update nickname
export const updateNicknameApi = (id, data) => {
  const formData = new FormData();

  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });
  let result = newRequest.patch(`/nickname/${id}`, formData);
  return result;
};
