import React from 'react'

function Logo() {
  return (
    <div>
      <img src="../askMeLogo.png" className="" alt="askMe Logo" />
    </div>
  );
}

export default Logo