import { createContext, useContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { sidebarMenuItems } from "../utils/constants";
import { useFetchData } from "../Hooks/useAxios";
import { ADMIN, PUBLIC } from "../utils/Queries";
import { useState } from "react";

const GlobalContextState = createContext();
const GlobalContextDispatch = createContext();

//!to the app component
const GlobalContext = ({ children }) => {
  const [GetRoleIsPending, setGetRoleIsPending] = useState(true);

  function reducer(state, action) {
    switch (action.type) {
      case "setUserData": {
        return {
          ...state,
          userData: action.payload,
        };
      }
      case "setRoleTabs": {
        return {
          ...state,
          roleTabs: action.payload,
        };
      }
      case "setPermissions": {
        return {
          ...state,
          permissions: action.payload,
        };
      }
      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [ContextState, ContextDispatch] = useReducer(reducer, {
    userData: JSON.parse(localStorage.getItem("user")) || null,
    roleTabs: [],
    permissions: [],
  });
  const roleId = ContextState?.userData?.roleId;

  const { data, error, isPending } = useFetchData({
    identifier: ADMIN.GET_ROLE,
    api: `${process.env.REACT_APP_GET_ROLE}/${roleId}`,
    select: true,
    id: roleId,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: !!roleId,
  });

  /*  const data = {
    key: "assistant",
    feature: ["get users", "delete plan"],
  }; */
  useEffect(() => {
    if (data) {
      console.log("data", data);
      if (data?.key === "admin") {
        ContextDispatch({ type: "setRoleTabs", payload: sidebarMenuItems });
        ContextDispatch({ type: "setPermissions", payload: ["admin"] });
      } else {
        const tabsBasedOnRole = sidebarMenuItems.filter(
          (tab) =>
            data?.feature?.some((permission) =>
              permission?.toLowerCase()?.includes(tab.permission)
            ) ||
            //||tab.label === "hr"
            tab.permission === "main" ||
            tab.permission === "settings" ||
            tab.permission === "bad-words"
        );
        console.log("tabsBasedOnRole", tabsBasedOnRole);
        ContextDispatch({ type: "setRoleTabs", payload: tabsBasedOnRole });
        ContextDispatch({ type: "setPermissions", payload: data?.feature });
      }
      setGetRoleIsPending(false);
    }
  }, [data]);

  return (
    <GlobalContextState.Provider
      value={{
        ...ContextState,
        GetRoleIsPending,
        GetRoleData: data,
      }}
    >
      <GlobalContextDispatch.Provider value={ContextDispatch}>
        {children}
      </GlobalContextDispatch.Provider>
    </GlobalContextState.Provider>
  );
};
//!to access the state
const useGlobalContextState = () => useContext(GlobalContextState);

//!to access the dispatch method
const useGlobalContextDispatch = () => useContext(GlobalContextDispatch);
export { GlobalContext, useGlobalContextState, useGlobalContextDispatch };
