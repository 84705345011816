import newRequest from "../utils/newRequest";

/// get all users
export const getAllUsersApi = (params) => {
  let result = newRequest.get(
    `/user/users?limit=10${Object.entries(params)
      .map((param) => `&${param[0]}=${param[1]}`)
      .join("")}`
  );
  return result;
};

/// delete user
export const deleteUserApi = (id) => {
  let result = newRequest.delete(`/user/${id}`);
  return result;
};

// get user by id
export const getUserByIdApi = (id) => {
  let result = newRequest.get(`/user/${id}`);
  return result;
};

// edit user by id
// using formData
export const editUserByIdApi = (id, data) => {
  const formData = new FormData();

  for (var key in data) {
    formData.append(key, data[key]);
    console.log(key, data[key]);
  }

  let result = newRequest.patch(`/user/crm/${id}`, formData);

  return result;
};

// create user
export const createUserApi = (data) => {
  const formData = new FormData();

  for (var key in data) {
    formData.append(key, data[key]);
    console.log(key, data[key]);
  }
  let result = newRequest.post("/user/crm", formData);
  return result;
};
