import axios from "axios";

const newRequest = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINTS_BASE_URL,

  withCredentials: true,
  // headers: {
  //   "Content-Type": "application/json",
  //   // "Content-Type": 'application/x-www-form-urlencoded',
  // },
});

export default newRequest;
