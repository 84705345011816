import newRequest from "../utils/newRequest";

/**
 * @type {countryData}
 */

/**
 * @typedef {Object} countryData
 * @property {Name} name - The name object containing translations.
 * @property {string} available - The availability count as a string.
 */

/**
 * @typedef {Object} Name
 * @property {string} en - The English name.
 * @property {string} ar - The Arabic NAME
 */

/// add Country
export const addCountryApi = (countryData) => {
  let result = newRequest.post("/country", countryData);
  return result;
};

/// get all Countries
export const getCountriesApi = () => {
  let result = newRequest.get("/country");
  return result;
};

/// update Country
export const updateCountryApi = (id, data) => {
  let result = newRequest.patch(`/country/${id}`, data);
  return result;
};

// get country
export const getCountryApi = (id) => {
  let result = newRequest.get(`/country/${id}`);
  return result;
};

// get country states
export const getCountryStatesApi = (id) => {
  let result = newRequest.get(`/country/${id}/state`);
  return result;
};

// delete country
export const deleteCountryApi = (id) => {
  let result = newRequest.delete(`/country/${id}`);
  return result;
};
