import { Layout, Menu, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { FiLogOut } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { useGlobalContextState } from "../../context/GlobalContext";
import { sidebarMenuItems } from "../../utils/constants";
import { ConfirmationModal } from "../Modals/ConfirmationModal";
import "./Sidebar.css";
const { Sider } = Layout;
export const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const GlobalContextState = useGlobalContextState();

  const [confirmLogoutOpen, setConfirmLogoutOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState(
    window.location.pathname === "/main" || window.location.pathname === "/"
      ? "1"
      : null
  );

  const [collapsed, setCollapsed] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    function handleSideNavToggle() {
      setCollapsed(width < 768 ? true : false);
    }
    window.addEventListener("resize", handleResize);
    handleSideNavToggle();
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  const handleMenuItemClicked = (item) => {
    if (item.key === "100") {
      setConfirmLogoutOpen(true);
    } else {
      const clickedItem = GlobalContextState.roleTabs?.find(
        (_item) => _item?.key === item?.key
      );
      if (clickedItem) {
        navigate(clickedItem?.path);
        setSelectedKey(clickedItem?.key);
      }
    }
  };
  //console.log("IsPending", GlobalContextState.GetRoleIsPending);
  useEffect(() => {
    if (GlobalContextState.roleTabs) {
      const selectedItem = GlobalContextState.roleTabs?.find(
        (item) => item?.path === location.pathname
      );
      if (selectedItem) {
        setSelectedKey(selectedItem?.key);
      }
    }
  }, [location, GlobalContextState.roleTabs]);

  const logoutHandler = () => {
    //props.token(false);
    localStorage.clear();
    // props.token(localStorage.getItem("token"));
    navigate("/login");
    //props.pathName("/login");
  };

  // scroll to selected menu key
  useEffect(() => {
    const selectedElement = document.querySelector(".ant-menu-item-selected");
    if (selectedElement) {
      selectedElement.scrollIntoView({ behavior: "instant", block: "center" });
    }
  }, [selectedKey]);

  return (
    <>
      <Sider
        width={"22%"}
        collapsible
        collapsed={collapsed}
        collapsedWidth={60}
        className='main_sidebar'
        //collapsedWidth=0 trigger={<Icon type="setting" />
        trigger={null}
      >
        <div className='logo'>
          <img
            alt='askMe Logo'
            src='./askMeLogo.png'
            className='sidebar-logo'
            style={{
              width: width > 767 ? "100px" : "45px",
              height: width > 767 ? "90px" : "60px",
            }}
          />
        </div>
        <div className='sidebar-menus-div'>
          <Menu
            className='editlist'
            theme='light'
            // defaultSelectedKeys={[selectedKey]}
            selectedKeys={[selectedKey]}
            mode='inline'
            onClick={handleMenuItemClicked}
          >
            {GlobalContextState.GetRoleIsPending ||
            GlobalContextState.roleTabs?.length === 0
              ? sidebarMenuItems?.map((item) =>
                  item?.label === "hr" ? (
                    <hr
                      key={item.key}
                      style={{
                        width: "80%",
                        margin: "10px 10%",
                        opacity: "0.3",
                      }}
                    />
                  ) : (
                    <Skeleton.Button
                      key={item.key}
                      active
                      block
                      size='large'
                      style={{ marginBlock: "10px" }}
                    />
                  )
                )
              : GlobalContextState.roleTabs?.map((item) =>
                  item?.label === "hr" ? (
                    <hr
                      key={item.key}
                      style={{
                        width: "80%",
                        margin: "10px 10%",
                        opacity: "0.3",
                      }}
                    />
                  ) : (
                    <Menu.Item
                      icon={item.icon}
                      className='sidebar_item'
                      key={item.key}
                      id={item?.key}
                    >
                      {item.label}
                    </Menu.Item>
                  )
                )}
            <Menu.Item
              icon={<FiLogOut className='sidebar_icon' />}
              className='sidebar_item'
              key='100'
              id='100'
              //  onClick={logoutHandler}
            >
              تسجيل الخروج
            </Menu.Item>
          </Menu>
        </div>
      </Sider>
      <ConfirmationModal
        title='تأكيد تسجيل الخروج'
        open={confirmLogoutOpen}
        onCancel={() => setConfirmLogoutOpen(false)}
        onClick={() => {
          localStorage.removeItem("user");
          navigate("/login");
        }}
        loading={null}
      />
    </>
  );
};
