import newRequest from "../utils/newRequest";

// sendNotification api
export const sendNotification = async (data) => {
  try {
    const res = await newRequest.post("/notification", data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
