export function formatSecondsArabic(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hoursDisplay =
    hours > 0 ? `${hours} ${hours === 1 ? "ساعة" : "ساعات"}` : "";
  const minutesDisplay =
    minutes > 0 ? `${minutes} ${minutes === 1 ? "دقيقة" : "دقائق"}` : "";
  const secondsDisplay = `${remainingSeconds} ${
    remainingSeconds === 1 ? "ثانية" : "ثواني"
  }`;

  return [hoursDisplay, minutesDisplay, secondsDisplay]
    .filter(Boolean)
    .join(", ");
}

export function formatSecondsToHoursArabic(seconds) {
  const hours = (seconds / 3600).toFixed(2);

  const hoursDisplay = `${hours} ${hours <= 1 ? "ساعة" : "ساعات"}`;

  return hoursDisplay;
}

export function formatDateDayMonthYear(input) {
  let date = new Date(input);
  // Get day, month, and year from the date object
  let day = date.getDate();
  let month = date.getMonth() + 1; // Months are zero-based
  let year = date.getFullYear();

  // Ensure day and month are two digits
  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  // Return the formatted date string
  const newDateString = day + "/" + month + "/" + year;

  return newDateString;
}

export function formatDateDistance(dateString) {
  const now = new Date();
  const targetDate = new Date(dateString);

  const diffMs = targetDate - now;
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  const absDays = Math.abs(diffDays);
  const absHours = Math.abs(diffHours);
  const absMinutes = Math.abs(diffMinutes);

  const absMonths = Math.floor(absDays / 30); // Approximate months as 30 days
  const remainingDays = absDays % 30;

  let result = "";

  if (diffMs > 0) {
    result = "متبقي ";
  } else {
    result = "إنتهى منذ ";
  }

  if (absMonths > 0) {
    result += `${absMonths} شهر (أشهر) `;
  }
  if (remainingDays > 0) {
    result += `${remainingDays} يوم (أيام) `;
  }
  if (absHours > 0) {
    result += `${absHours} ساعة (ساعات) `;
  }

  result += `${absMinutes} دقيقة (دقائق) `;

  return result;
}

export function formatDateToPostTime(dateString) {
  // Create a Date object from the input string
  const date = new Date(dateString);

  // Get the hours and determine AM/PM
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Pad minutes with leading zeros if necessary
  const minutesStr = minutes < 10 ? "0" + minutes : minutes;

  // Get the day, month, and year
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const year = date.getFullYear();

  // Construct the formatted date string
  const formattedDate = `${hours}: ${minutesStr} ${ampm}  - ${month}/${day}/${year}`;

  return formattedDate;
}
