import newRequest from "../utils/newRequest";

/// get all packages

export const getAllPackagesApi = (type) => {
  let result = newRequest.get(`/plan?type=${type}`);
  return result;
};

/// add package
export const addPackageApi = (data) => {
  let result = newRequest.post(`/plan`, data);
  return result;
};

/// edit package
export const editPackageApi = (id, data) => {
  let result = newRequest.put(`/plan/${id}`, data);
  return result;
};

///delete package
export const deletePackageApi = (id) => {
  let result = newRequest.delete(`/plan/${id}`);
  return result;
};
