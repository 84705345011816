import newRequest from "../utils/newRequest";

// get question api
export const getQuestion = async (id) => {
  const res = await newRequest.get(`/posts/${id}`);
  return res;
};

// get post comments
export const getPostComments = async (id) => {
  const res = await newRequest.get(`/comment/post/${id}`);
  return res;
};

// get comment replies
export const getCommentReplies = async (id) => {
  const res = await newRequest.get(`/comment/${id}/replies`);
  return res;
};
