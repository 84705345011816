import newRequest from "../utils/newRequest";

/// add term
export const addTermApi = (data) => {
  let result = newRequest.post("/term", data);
  return result;
};

/// get terms
export const getTermsApi = () => {
  let result = newRequest.get("/term");
  return result;
};

/// update term
export const updateTermApi = (id, data) => {
  let result = newRequest.put(`/term/${id}`, data);
  return result;
};

export const getLocalizedTermsApi = () => {
  let result = newRequest.get(`/crm/term/`);
  return result;
};
