import React, { useState, useEffect } from "react";
import "./Main.css";
import { getAllUsersApi, getAllReportsApi } from "../../APIs/MainApis";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { getAllPackagesApi } from "../../APIs/PackagesApis";
import { url } from "../../APIs/MainApis";

export const Main = () => {
  const [users, setUsers] = useState([]);
  const [totalUsers, settotalUsers] = useState(0);
  const [packages, setPackages] = useState([]);
  const [reports, setReports] = useState([]);
  const [onlineUsers, setOnlineUsers] = useState("");
  const [questions, setQuestions] = useState("");
  const [reportType, setReportType] = useState("post");
  const navigate = useNavigate();

  useEffect(() => {
    getAllUsersFunc();
    getAllPackagesFunc();
  }, []);
  useEffect(() => {
    getAllReportsFunc();
  }, [reportType]);

  /// get all users function
  const getAllUsersFunc = async () => {
    try {
      let response = await getAllUsersApi();
      let users = await response?.data?.data;
      let online = await response?.data?.onlineUsers;
      let questions = await response?.data?.sponsierPost;
      setUsers([...users]);
      settotalUsers(response?.data.pagination?.resultCount);
      setOnlineUsers(online);
      setQuestions(questions);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  /// get all packages
  const getAllPackagesFunc = async () => {
    try {
      let response = await getAllPackagesApi();
      let packages = await response?.data?.data;
      setPackages([...packages]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  /// get all reports
  const getAllReportsFunc = async () => {
    try {
      let response = await getAllReportsApi(reportType);
      let reports = await response?.data?.data;
      setReports([...reports]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  return (
    <>
      <div className='main-main'>
        <p>
          <span className='main-control-txt'>لوحة التحكم /</span>{" "}
          <span style={{ fontSize: "18px" }}>الرئيسية</span>
        </p>
        <div className='main-top-cards-div'>
          <div className='main-top-cards'>
            <p className='main-top-cards-txt'>المستخدمون المسجلون</p>
            <p
              className='main-top-cards-num'
              style={{ color: "rgba(69, 120, 175, 1)" }}
            >
              {totalUsers}
            </p>
          </div>
          <div className='main-top-cards'>
            <p className='main-top-cards-txt'>المستخدمون النشطون</p>
            <p
              className='main-top-cards-num'
              style={{ color: "rgba(249, 166, 54, 1)" }}
            >
              {onlineUsers}
            </p>
          </div>
          <div className='main-top-cards'>
            <p className='main-top-cards-txt'>عدد الأسئلة الممولة</p>
            <p
              className='main-top-cards-num'
              style={{ color: "rgba(69, 120, 175, 1)" }}
            >
              {questions}
            </p>
          </div>
          {/* <div className="main-top-cards">
            <p className="main-top-cards-txt">نشاط تسجيل الدخول</p>
            <p
              className="main-top-cards-num"
              style={{ color: "rgba(249, 166, 54, 1)" }}
            >
              500
            </p>
          </div> */}
        </div>
        <div className='main-tables' style={{ marginTop: "20px" }}>
          <div className='main-admin-employees'>
            {/* {localStorage.getItem("jobName") == "admin" ? ( */}
            <div className='main-employees'>
              <div className='main-employees-header-div'>
                {/* <Row
                  style={{
                    padding: "15px",

                    borderTopRightRadius: "14px",
                    borderTopLeftRadius: "14px",
                    padding: "15px",
                    // zIndex:"200"
                  }}
                >
                  <Col xs={24}> */}
                <p className='main-employees-header-txt'>
                  عدد المشتركين فى كل باقة
                </p>
                {/* </Col>
                </Row> */}
              </div>
              <div className='main-employees-body'>
                <div className='main-packages-wrapper'>
                  {packages?.map((pack) => (
                    <>
                      {/* <div style={{ margin: "10px 5px" }}> */}
                      <div className='main-package-card' key={pack?.id}>
                        <div className='main-card-titles'>
                          <p style={{ fontSize: "20px" }}>
                            باقة {pack?.time === 2 ? "" : pack?.time}{" "}
                            {pack?.time === 2
                              ? "ساعتين"
                              : pack?.time >= 3 && pack?.time <= 10
                              ? "ساعات"
                              : "ساعة"}
                          </p>
                          <p
                            className='main-card-address'
                            style={{
                              color: "rgba(69, 120, 175, 1)",
                              fontSize: "30px",
                              fontWeight: "700",
                            }}
                          >
                            {pack?.userCount}
                          </p>
                          <p
                            className='main-card-time'
                            style={{
                              fontSize: "16px",
                              color: "rgba(169, 169, 169, 1)",
                            }}
                          >
                            {" "}
                            مستخدم
                          </p>
                        </div>
                      </div>
                      {/* </div> */}
                    </>
                  ))}
                </div>
              </div>
            </div>
            {/* ) : null} */}
          </div>
          <div className='main-crud-div-wrapper'>
            <div className='main-admin-employees' style={{ width: "100%" }}>
              {/* {localStorage.getItem("jobName") == "admin" ? ( */}
              <div
                className='main-employees'
                style={{ maxHeight: "600px", overflow: "auto" }}
              >
                <div
                  style={{
                    position: "sticky",
                    top: "0",
                    zIndex: "8",
                    backgroundColor: "rgba(252, 252, 252, 1)",
                    minWidth: "400px",
                  }}
                >
                  <div
                    style={{
                      padding: "15px",

                      borderTopRightRadius: "14px",
                      borderTopLeftRadius: "14px",
                      padding: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // zIndex:"200"
                    }}
                  >
                    {/* <Col xs={14}> */}
                    <p style={{ fontSize: "16px" }}> البلاغات</p>
                    {/* </Col> */}
                    {/* <Col xs={5}> */}
                    <div>
                      <button
                        onClick={() => setReportType("post")}
                        className={
                          reportType === "post"
                            ? "reportClicked"
                            : "reportUnClicked"
                        }
                      >
                        الأسئلة
                      </button>
                      {/* </Col>
                    <Col xs={5}> */}
                      <button
                        onClick={() => setReportType("comment")}
                        className={
                          reportType === "comment"
                            ? "reportClicked"
                            : "reportUnClicked"
                        }
                      >
                        الأجوبة
                      </button>
                    </div>
                    {/* </Col> */}
                  </div>
                </div>
                <div
                  className='main-employees-body'
                  style={{ padding: "15px" }}
                >
                  <div
                    className='pack-packages-wrapper'
                    style={{ minWidth: "400px" }}
                  >
                    {reports?.map((pack) => (
                      <>
                        <div
                          style={{
                            padding: "20px 0px",
                            borderBottom: "1px solid lightgray",
                          }}
                          key={pack?._id}
                        >
                          <div
                            className='main-admin'
                            style={{
                              display: "flex",
                              backgroundColor: "rgba(255,255,255,1)",
                              //   boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
                              borderRadius: "14px",
                              padding: "15px",
                            }}
                          >
                            <img
                              src={
                                pack?.user?.profileImage
                                  ? url + pack?.user?.profileImage
                                  : "./profile.svg"
                              }
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                                lineHeight: "100px",
                                boxShadow: "0 0 4px gray",
                              }}
                              alt='profile Image'
                            />
                            <div
                              className='main-admin-data'
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "flex-start",
                                marginRight: "20px",
                              }}
                            >
                              <p
                                style={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                {pack?.user?.userName}
                              </p>
                              <p
                                style={{
                                  fontSize: "16px",
                                  color: "rgba(172, 172, 172, 1)",
                                  fontWeight: "600",
                                }}
                              >
                                {pack?.createdAt.split("T")[0]}
                              </p>
                            </div>
                          </div>
                          <p>
                            {reportType === "post"
                              ? pack?.post?.description
                              : pack?.comment?.comment}
                          </p>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
