import React from "react";
import ErrorIllustration from "./404.png"; // Replace with your SVG file path

const NotFoundPage = () => {
  return (
    <div className='min-h-screen flex flex-col items-center justify-center bg-gray-100'>
      <div className='mt-8'>
        <img
          src={ErrorIllustration}
          alt='404 Illustration'
          className=' w-96 h-auto'
        />
      </div>
      <div className='max-w-lg mx-auto p-8 pt-0  rounded-lg  text-center'>
        <h2 className='text-4xl font-bold text-gray-800 mb-6'>
          404 - لا وجود لهذه الصفحة
        </h2>
        <p className='text-gray-600 mb-8'>
          عفوا! ربما تمت إزالة الصفحة التي تبحث عنها أو تم تغيير اسمها.
        </p>
        <a
          href='/'
          className='bg-blue-500 hover:bg-blue-600 text-white hover:!text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
        >
          العودة للرئيسية
        </a>
      </div>
    </div>
  );
};

export default NotFoundPage;
