import React, { useState, useEffect } from "react";
import "./Sections.css";
import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, message, Button } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import newRequest from "../../utils/newRequest";
import {
  addSectionApi,
  getAllCategoriesApi,
  deleteSectionApi,
  editSectionApi,
} from "../../APIs/SectionsApis";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";

export const Sections = () => {
  const [addNewSectionOpen, setAddNewSectionOpen] = useState(false);
  const [editSectionOpen, setEditSectionOpen] = useState(false);
  const [confirmEditSectionOpen, setConfirmEditSectionOpen] = useState(false);
  const [confirmAddSectionOpen, setConfirmAddSectionOpen] = useState(false);
  const [confirmDeleteSectionOpen, setConfirmDeleteSectionOpen] =
    useState(false);
  const [addSection, setAddSection] = useState("");
  const [addSectionEn, setAddSectionEn] = useState("");
  const [addSectionError, setAddSectionError] = useState(false);
  const [editSection, setEditSection] = useState("");
  const [editSectionObj, setEditSectionObj] = useState({});
  const [editSectionSentObj, setEditSectionSentObj] = useState({});
  const [sectionId, setSectionId] = useState("");

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  /// useEffects
  useEffect(() => {
    getCategoriesFunc();
  }, []);

  // get Categories func
  const getCategoriesFunc = async () => {
    try {
      let response = await getAllCategoriesApi();

      let data = await response?.data?.data;
      setCategories([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  // add section func
  const addSectionFunc = async () => {
    if (addSection.trim() == "") {
      setAddSectionError(true);
    } else {
      setLoading(true);
      try {
        let response = await addSectionApi({
          name: {
            ar: addSection,
            en: addSectionEn,
          },
        });
        message.success(response?.data?.message);
        setLoading(false);
        setAddNewSectionOpen(false);
        setConfirmAddSectionOpen(false);
        setAddSection("");
        getCategoriesFunc();
      } catch (error) {
        setLoading(false);
        let errorsArr = [];

        let errors = await error?.response?.data?.errors;
        errors.map((e) => {
          let error = e.field + " " + e.message;
          errorsArr.push(error);
        });
        message.error(errorsArr.join());
      }
    }
  };
  // delete section Func
  const deleteSectionFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteSectionApi(sectionId);
      setLoading(false);
      setConfirmDeleteSectionOpen(false);
      getCategoriesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + " " + e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  /// edit section change
  const editSectionChange = (e) => {
    let { name, value } = e.target;
    setEditSectionObj((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setEditSection(value);
    console.log(value);
  };

  const editSectionFunc = async () => {
    setConfirmEditSectionOpen(true);
    let obj = {};
    Object.keys(editSectionObj)?.forEach((key) => {
      const value = editSectionObj[key];
      if (value) {
        obj[key] = value;
      }
    });
    setEditSectionSentObj(obj);
  };
  const confirmEditSectionFunc = async () => {
    setLoading(true);
    try {
      let response = await editSectionApi(sectionId, {
        name: {
          ar: editSection.ar,
          en: editSection.en,
        },
      });
      setEditSectionObj({});
      setEditSectionSentObj({});
      setLoading(false);
      setConfirmEditSectionOpen(false);
      setEditSectionOpen(false);
      message.success(response?.data?.message);
      getCategoriesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field + " " + e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };
  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='sec-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الأقسام</span>
          </p>
          <button
            className='sec-add-sec-btn'
            onClick={() => {
              setAddNewSectionOpen(true);
            }}
          >
            إضافة قسم جديد
          </button>
        </div>
        <div className='sec-table'>
          <div style={{ minWidth: "500px" }}>
            <Row className='sec-table-head-row'>
              <Col xs={2} style={{ fontSize: "20px", fontWeight: "600" }}>
                #
              </Col>
              <Col xs={16} style={{ fontSize: "20px", fontWeight: "600" }}>
                اسم القسم
              </Col>
              <Col xs={6}></Col>
            </Row>
          </div>
          <div
            style={{ minWidth: "500px", maxHeight: "400px", overflow: "auto" }}
          >
            {categories?.map((cat, index) => (
              <Row className='sec-table-row'>
                <Col xs={2} className='sec-table-col'>
                  {index + 1}
                </Col>
                <Col xs={16} className='sec-table-col'>
                  {cat?.name.en} - {cat?.name.ar}
                </Col>
                <Col xs={6}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span
                      className='sec-table-edit'
                      onClick={() => {
                        setEditSection(cat?.name);
                        setEditSectionOpen(true);
                        setSectionId(cat?._id);
                      }}
                    >
                      <BiEditAlt style={{ fontSize: "20px" }} />
                    </span>
                    <span
                      className='sec-table-delete'
                      onClick={() => {
                        setSectionId(cat?._id);
                        setConfirmDeleteSectionOpen(true);
                      }}
                    >
                      <FaRegTrashAlt style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                </Col>
              </Row>
            ))}
          </div>
        </div>
      </div>
      {/***** add new section******/}
      <Modal
        title='إضافة قسم جديد'
        className='sec-add-section-modal'
        open={addNewSectionOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddNewSectionOpen(false);
          setAddSection("");
        }}
        footer={null}
      >
        <div className='sec-add-sec-modal-body'>
          <input
            type='text'
            className='sec-add-sec-modal-input'
            placeholder=' الإسم بالعربية'
            value={addSection}
            onChange={(e) => {
              setAddSection(e.target.value);
              if (e.target.value !== "") {
                setAddSectionError(false);
              }
            }}
          />
          {addSectionError && (
            <p style={{ color: "red" }}>من فضلك ادخل اسم القسم</p>
          )}
          <input
            type='text'
            className='sec-add-sec-modal-input my-4'
            placeholder=' Name in English '
            value={addSectionEn}
            onChange={(e) => {
              setAddSectionEn(e.target.value);
              if (e.target.value !== "") {
                setAddSectionError(false);
              }
            }}
          />
          {addSectionError && (
            <p style={{ color: "red" }}>من فضلك ادخل اسم القسم</p>
          )}
          <Button
            className='sec-add-sec-modal-btn'
            onClick={() => setConfirmAddSectionOpen(true)}
            // loading={loading}
          >
            إضافة
          </Button>
        </div>
      </Modal>

      {/***** edit section******/}
      <Modal
        title='تعديل القسم'
        className='sec-add-section-modal'
        open={editSectionOpen}
        onOk={() => {}}
        onCancel={() => setEditSectionOpen(false)}
        footer={null}
      >
        <div className='sec-add-sec-modal-body'>
          <input
            name='name'
            type='text'
            className='sec-add-sec-modal-input'
            placeholder='الاسم بالعربية'
            value={editSection.ar}
            onChange={(e) =>
              setEditSection({ ...editSection, ar: e.target.value })
            }
          />
          <input
            name='name'
            type='text'
            className='sec-add-sec-modal-input my-4'
            placeholder='Name in English '
            value={editSection.en}
            onChange={(e) =>
              setEditSection({ ...editSection, en: e.target.value })
            }
          />
          <div className='sec-edit-sec-modal-btns'>
            <button
              className='sec-edit-sec-modal-okBtn'
              onClick={editSectionFunc}
            >
              تعديل
            </button>
            <button
              className='sec-edit-sec-modal-cancelBtn'
              onClick={() => setEditSectionOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>
      {/***** confirm edit section******/}
      <ConfirmationModal
        title='تأكيد تعديل القسم'
        open={confirmEditSectionOpen}
        onClick={confirmEditSectionFunc}
        onCancel={() => setConfirmEditSectionOpen(false)}
        loading={loading}
      />
      {/***confirm add section** */}
      <ConfirmationModal
        title='تأكيد إضافة القسم'
        open={confirmAddSectionOpen}
        onClick={addSectionFunc}
        onCancel={() => {
          setAddNewSectionOpen(false);
          setConfirmAddSectionOpen(false);
          setAddSection("");
          setAddSectionEn("");
        }}
        loading={loading}
      />
      {/***confirm delete section** */}
      <ConfirmationModal
        title='تأكيد حذف القسم'
        open={confirmDeleteSectionOpen}
        onClick={deleteSectionFunc}
        onCancel={() => {
          setConfirmDeleteSectionOpen(false);
        }}
        loading={loading}
      />
      {/* <Modal
        title="تأكيد تعديل القسم"
        className="sec-add-section-modal"
        open={confirmEditSectionOpen}
        onOk={() => {}}
        onCancel={() => setConfirmEditSectionOpen(false)}
        footer={null}
      >
        <div className="sec-add-sec-modal-body">
          <div className="sec-edit-sec-modal-btns">
            <button
              className="sec-edit-sec-modal-okBtn"
              onClick={confirmEditSectionFunc}
            >
              تأكيد
            </button>
            <button
              className="sec-edit-sec-modal-cancelBtn"
              onClick={() => setConfirmEditSectionOpen(false)}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
};
