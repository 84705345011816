import React, {useState,useEffect} from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom";
import { url } from "../../APIs/MainApis";
import SearchBox from "../Search/Search";
import { getLoggedUserApi } from "../../APIs/SettingsApis";
//import { getLoggedUserApi, url } from "../../APIs/SettingsPageAPIs";

export const Header = (props) => {
  const [loggedUser,setLoggedUser] = useState({});
const navigate = useNavigate()
useEffect(()=>{
getLoggedUser()
},[])

  const getLoggedUser = async()=>{
    try{
       let response = await getLoggedUserApi();
       let data = response?.data?.data
setLoggedUser(data)
    }catch(error){
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  }
 
  return (
    <>
      <div className="header-main">
        {/* <SearchBox /> */}
        <div
         
          className="header-imgs-div"
        >
          <div className="notifi-profile" >
            {/* <img
              src="./notifi.png"
             
              style={{width:"45px",height:"45px",margin:"0px 15px"}}
            /> 
            <span 
            className="header-span-imgs">
              
  </span> */}
  
            <img
              src={loggedUser?.profileImage?(url+loggedUser.profileImage):"./profile.svg"}
              alt="image"
              className="header-imgs"
              
            />
          </div>
          <div>
            <span style={{color:"black",opacity:"0.4",fontSize:"18px"}}>مرحبا</span>
            <span style={{color:"black",fontSize:"18px"}}> {loggedUser?.userName}</span>
          </div>
        </div>
      </div>
    </>
  );
};
