import newRequest from "../utils/newRequest";


/// get logged user
export const getLoggedUserApi = ()=>{
    let result = newRequest.get("/user/loggedUser");
    return result;
}

/// change image
export const editImageApi = (data)=>{
    const formData = new FormData();

  // Object.keys(data)?.forEach((key) => {
  //   formData.append(key, data[key]);
  // });
  formData.append("profileImage",data)
    let result = newRequest.put("/user/update-profile-image", formData, {
        
        headers: {
          "Content-Type": "multipart/form-data",
        }});
      return result;
}

/// edit user
export const editProfileApi = (data)=>{
    let result = newRequest.put("/user/update-profile",data);
    return result
}