import { useEffect, useState } from "react";
import "./Questions.css";
import { Table, Switch } from "antd";
import { getPinnedQuestionsApi } from "../../APIs/PinnedQuestionsApis";
import {
  formatSecondsToHoursArabic,
  formatDateDistance,
} from "../../utils/time";
import { Button, Modal, Select, message } from "antd";
import { getCountriesApi, updateCountryApi } from "../../APIs/CountriesApis";
import { getTermsApi, updateTermApi } from "../../APIs/TermsApis";
import { UsePermission } from "../../Hooks/UsePermission";
import { getCountryApi } from "../../APIs/CountriesApis";

export const Questions = () => {
  const { hasPermission } = UsePermission();
  const [questions, setQuestions] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 10,
  });
  // limit questions modal states
  const [showLimitQuestions, setshowLimitQuestions] = useState(false);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [count, setcount] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  // turn pinned questions on/off states
  const [term, setTerm] = useState(false);

  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "اسم المستخدم",
      dataIndex: "userId",
      render: (userId) => userId?.userName,
      className: "center-align",
    },
    {
      title: "السؤال",
      dataIndex: "description",
      className: "center-align",
    },
    {
      title: "نوع الباقة",
      dataIndex: "sponsierd",
      render: (sponsierd) =>
        "باقة " + formatSecondsToHoursArabic(sponsierd.time),
      className: "center-align",
    },
    {
      title: "الوقت المتبقي",
      dataIndex: "sponsierd",
      render: (sponsierd) => formatDateDistance(sponsierd.expiresAt),
      className: "center-align",
    },
    {
      title: "الدولة",
      dataIndex: "country",
      render: (country) => country?.name.name.ar,
      className: "center-align",
    },
  ];

  /// useEffects
  useEffect(() => {
    getAllPinnedQuestionsFunc();
  }, [page]);

  useEffect(() => {
    (async () => {
      const countries = (await getCountriesApi()).data.data;

      setCountries(countries);
    })();
  }, [showLimitQuestions]);

  useEffect(() => {
    (async () => {
      const term = (await getTermsApi()).data.data;
      setTerm(term);
    })();
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      (async () => {
        const country = await getCountryApi(selectedCountry);
        setcount(country.data.data.available);
      })();
    }
  }, [selectedCountry]);

  /// get all questions func
  const getAllPinnedQuestionsFunc = async () => {
    setLoading(true);
    try {
      let res = await getPinnedQuestionsApi(page);
      setQuestions(res?.data?.data);
      setLoading(false);
      const currentPage = res?.data?.pagination?.currentPage;
      const totalPages = res?.data?.pagination?.totalPages;
      const resultCount = res?.data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
    } catch (e) {
      setLoading(false);
    }
  };

  /// handle page change
  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };

  // handle limit setup
  const handleLimitSetup = async () => {
    const country = countries.find(
      (country) => country._id === selectedCountry
    );
    console.log(country);
    await updateCountryApi(country._id, { available: count });
    setshowLimitQuestions(false);
    message.success("تم تحديد عدد الاسئله بنجاح");
  };

  // hangle sponosred visiblity toggle
  const handleSponsoredVisibilityChange = async (checked) => {
    try {
      await updateTermApi(term._id, { sponsoredVisibility: checked });
      setTerm({ ...term, sponsoredVisibility: checked });
    } catch (error) {
      message.error(error.message || "حدث خطأ ما");
    }
  };

  return (
    <>
      <div className='main-main'>
        <div className='flex w-[95%] items-start justify-between'>
          <p>
            <span className='main-control-txt'>لوحة التحكم /</span>{" "}
            <span style={{ fontSize: "18px" }}>الأسئلة المثبتة</span>
          </p>
          <div className='flex gap-6 items-center'>
            {hasPermission("update country") && (
              <Button
                onClick={() => setshowLimitQuestions(true)}
                type='primary'
                className='bg-primary p-3 rounded-[10px] h-fit hover:!bg-primary'
              >
                تحديد عدد الاسئله المثبته
              </Button>
            )}
            {hasPermission("update term") && (
              <div className='gap-2 flex items-center'>
                <p className='text-xl'>ظهور الأسألة المثبتة</p>

                <Switch
                  checked={term.sponsoredVisibility}
                  onChange={handleSponsoredVisibilityChange}
                  className=' p-3 rounded-[10px] h-fit aria-checked:bg-primary aria-checked:hover:!bg-primary '
                />
              </div>
            )}
          </div>
        </div>
        <div
          className='tick-table'
          // style={{ padding: "0px 0px 10px" }}
        >
          <Table
            loading={loading}
            columns={columns}
            dataSource={questions}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <Modal
        // title="تأكيد تعديل الباقة"
        className='rules-add-rules-modal'
        open={showLimitQuestions}
        onOk={() => {}}
        onCancel={() => {
          setshowLimitQuestions(false);
        }}
        footer={null}
        closable={false}
      >
        <div className='rules-add-rules-modal-body'>
          <p className='text-xl'>تحديد عدد الاسئله المثبته</p>

          <div className='my-10 w-full'>
            <div className='form-control'>
              <input
                type='text'
                placeholder='عدد الاسئله '
                name='count'
                onChange={(e) => setcount(e.target.value)}
                value={count}
              />
            </div>
            <Select
              placeholder={"الدولة"}
              name='countries'
              className='sett-select-menu'
              style={{ marginTop: "15px" }}
              value={selectedCountry}
              onChange={(value) => setSelectedCountry(value)}
            >
              {countries.map((country) => (
                <Select.Option key={country._id} value={country._id}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className='rules-edit-rules-modal-btns'>
            <Button
              disabled={!count || !selectedCountry}
              className='rules-edit-rules-modal-okBtn disabled:!bg-gray-400 disabled:hover:!bg-gray-400 disabled:!cursor-auto'
              onClick={() => handleLimitSetup()}
            >
              تحديد
            </Button>
            <Button
              className='rules-edit-rules-modal-cancelBtn'
              onClick={() => {
                setshowLimitQuestions(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
