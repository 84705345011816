import { Button, Modal, Table } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import { useFetchData, usePostData } from "../../Hooks/useAxios";
import { ADMIN } from "../../utils/Queries";
import { BiEditAlt } from "react-icons/bi";
import { FaRegTrashAlt } from "react-icons/fa";
import "./Countries.css";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { UsePermission } from "../../Hooks/UsePermission";

function CitiesTable({ country }) {
  const { hasPermission } = UsePermission();

  const [page, setPage] = useState(1);
  const [ToggleAddStateModal, setToggleAddStateModal] = useState(false);
  const [stateArName, setStateArName] = useState("");
  const [stateArNameError, setStateArNameError] = useState("");
  const [stateEnName, setStateEnName] = useState("");
  const [stateEnNameError, setStateEnNameError] = useState("");

  const initialState = {
    deletedStateId: "",
    toggleDeleteStateModal: false,
    updatedStateId: "",
  };

  const ReducerFunction = (state, action) => {
    switch (action.type) {
      case "SetDeletedStateId":
        return {
          ...state,
          deletedStateId: action.payload,
        };

      case "SetToggleDeleteStateModal":
        return {
          ...state,
          toggleDeleteStateModal: action.payload,
        };
      case "SetResetDeleteStateConfirmationModal":
        return {
          ...state,
          deletedStateId: "",
          toggleDeleteStateModal: false,
        };
      case "SetUpdatedStateId":
        return {
          ...state,
          updatedStateId: action.payload,
        };

      default:
        throw Error("Unknown action: " + action.type);
    }
  };

  const [state, dispatch] = useReducer(ReducerFunction, initialState);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 20,
  });
  const { data, isPending, refetch } = useFetchData({
    identifier: ADMIN.GET_CITIES_OF_COUNTRY,
    api: `${process.env.REACT_APP_COUNTRY}/${country?._id}/state`,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    select: true,
    id: country?._id,
  });
  const { data: stateDetails } = useFetchData({
    identifier: ADMIN.GET_STATE_DETAILS,
    api: `${process.env.REACT_APP_STATE}/${state?.updatedStateId}`,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: !!state?.updatedStateId,
    select: true,
    id: state?.updatedStateId,
  });
  useEffect(() => {
    if (stateDetails) {
      setStateArName(stateDetails?.name?.ar);
      setStateEnName(stateDetails?.name?.en);
    }
  }, [stateDetails]);

  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "المدينة",
      dataIndex: "stateName",
      className: "center-align",
    },
    {
      key: "action",
      className: "center-align",
      render: (_, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {" "}
          {hasPermission("update state") && (
            <span
              className="sec-table-edit"
              onClick={() => {
                dispatch({ type: "SetUpdatedStateId", payload: record?._id });
                setToggleAddStateModal(true);
              }}
            >
              <BiEditAlt style={{ fontSize: "20px" }} />
            </span>
          )}
          {hasPermission("delete state") && (
            <span
              className="sec-table-delete"
              onClick={() => {
                dispatch({ type: "SetDeletedStateId", payload: record?._id });
                dispatch({ type: "SetToggleDeleteStateModal", payload: true });
              }}
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </span>
          )}
        </div>
      ),
    },
  ];
  const { mutate: UpdateState, isPending: UpdateStateIsPending } = usePostData(
    true,
    () => {
      setToggleAddStateModal(false);
      setStateArName("");
      setStateEnName("");
      refetch();
    }
  );
  useEffect(() => {
    if (data) {
      const currentPage = data?.pagination?.currentPage;
      const totalPages = data?.pagination?.totalPages;
      const resultCount = data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
    }
  }, [data]);
  const { mutate: DeleteState, isPending: DeleteStateIsPending } = usePostData(
    true,
    () => {
      dispatch({ type: "SetResetDeleteStateConfirmationModal" });
      refetch();
    }
  );
  const deleteStateFunc = () => {
    DeleteState({
      api: `${process.env.REACT_APP_STATE}/${state.deletedStateId}`,
      method: "DELETE",
    });
  };
  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };
  const addUpdateStateFunc = () => {
    if (stateArName === "") {
      setStateArNameError(true);
    } else if (stateEnName === "") {
      setStateEnNameError(true);
    } else {
      UpdateState({
        api: `${process.env.REACT_APP_STATE}/${state.updatedStateId}`,
        data: {
          name: {
            ar: stateArName,
            en: stateEnName,
          },
        },
        method: "PATCH",
      });
    }
  };
  return (
    <>
      <div className="tick-table">
        <Table
          loading={isPending}
          columns={columns}
          dataSource={data}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="تعديل المدينة "
        className="sec-add-section-modal"
        open={ToggleAddStateModal}
        onOk={() => {}}
        onCancel={() => {
          setToggleAddStateModal(false);
          setStateArName("");
          setStateEnName("");

          dispatch({ type: "SetUpdatedStateId", payload: "" });
        }}
        footer={null}
      >
        <div className="sec-add-sec-modal-body space-y-5">
          <label className="w-full" htmlFor="ar_name">
            <input
              id="ar_name"
              type="text"
              className="sec-add-sec-modal-input"
              placeholder="اسم المدينة باللغة بالعربية"
              value={stateArName}
              onChange={(e) => {
                setStateArName(e.target.value);
                if (e.target.value !== "") {
                  setStateArNameError(false);
                }
              }}
            />
            {stateArNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم المدينة باللغة بالعربية{" "}
              </p>
            )}
          </label>
          <label className="w-full" htmlFor="en_name">
            <input
              id="en_name"
              type="text"
              dir="ltr"
              className="sec-add-sec-modal-input"
              placeholder="اسم المدينة باللغة الانجليزية"
              value={stateEnName}
              onChange={(e) => {
                setStateEnName(e.target.value);
                if (e.target.value !== "") {
                  setStateEnNameError(false);
                }
              }}
            />
            {stateEnNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم المدينة باللغة الانجليزية
              </p>
            )}
          </label>
          <Button
            className="sec-add-sec-modal-btn"
            onClick={addUpdateStateFunc}
            loading={UpdateStateIsPending}
          >
            تعديل
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        title="تأكيد حذف المدينة"
        open={state.toggleDeleteStateModal}
        loading={DeleteStateIsPending}
        onCancel={() =>
          dispatch({ type: "SetResetDeleteStateConfirmationModal" })
        }
        onClick={deleteStateFunc}
      />
    </>
  );
}

export default CitiesTable;
