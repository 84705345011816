import { useState } from "react";
import { usePostData } from "../../Hooks/useAxios";
import { Button, Modal } from "antd";
import { FaPlus } from "react-icons/fa";
import { useQueryClient } from "@tanstack/react-query";
import { ADMIN } from "../../utils/Queries";

function AddStateToCountryAction({ country, refetch }) {
  const [ToggleAddStateModal, setToggleAddStateModal] = useState(false);
  const [stateArName, setStateArName] = useState("");
  const [stateArNameError, setStateArNameError] = useState("");
  const [stateEnName, setStateEnName] = useState("");
  const [stateEnNameError, setStateEnNameError] = useState("");
  const queryClient = useQueryClient();

  const { mutate: AddState, isPending: AddStateIsPending } = usePostData(
    true,
    () => {
      setToggleAddStateModal(false);
      setStateArName("");
      setStateEnName("");
      queryClient.invalidateQueries({
        queryKey: [ADMIN.GET_CITIES_OF_COUNTRY, country?._id],
      });
    }
  );
  const addStateFunc = () => {
    if (stateArName === "") {
      setStateArNameError(true);
    } else if (stateEnName === "") {
      setStateEnNameError(true);
    } else {
      AddState({
        api: process.env.REACT_APP_STATE,
        data: {
          name: {
            ar: stateArName,
            en: stateEnName,
          },
          country: country?._id,
        },
      });
    }
  };
  return (
    <>
      <span
        className="sec-table-edit"
        onClick={() => {
          setToggleAddStateModal(true);
        }}
      >
        <FaPlus className="text-primary" style={{ fontSize: "17px" }} />
      </span>
      <Modal
        title={`إضافة مدينة جديدة الي دولة ${country?.name}`}
        className="sec-add-section-modal"
        open={ToggleAddStateModal}
        onOk={() => {}}
        onCancel={() => {
          setToggleAddStateModal(false);
          setStateArName("");
          setStateEnName("");
        }}
        footer={null}
      >
        <div className="sec-add-sec-modal-body space-y-5">
          <label className="w-full" htmlFor="ar_name">
            <input
              id="ar_name"
              type="text"
              className="sec-add-sec-modal-input"
              placeholder="اسم المدينة باللغة بالعربية"
              value={stateArName}
              onChange={(e) => {
                setStateArName(e.target.value);
                if (e.target.value !== "") {
                  setStateArNameError(false);
                }
              }}
            />
            {stateArNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم المدينة باللغة بالعربية{" "}
              </p>
            )}
          </label>
          <label className="w-full" htmlFor="en_name">
            <input
              id="en_name"
              type="text"
              dir="ltr"
              className="sec-add-sec-modal-input"
              placeholder="اسم المدينة باللغة الانجليزية"
              value={stateEnName}
              onChange={(e) => {
                setStateEnName(e.target.value);
                if (e.target.value !== "") {
                  setStateEnNameError(false);
                }
              }}
            />
            {stateEnNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم المدينة باللغة الانجليزية
              </p>
            )}
          </label>
          <Button
            className="sec-add-sec-modal-btn"
            onClick={addStateFunc}
            loading={AddStateIsPending}
          >
            إضافة
          </Button>
        </div>
      </Modal>
    </>
  );
}

export default AddStateToCountryAction;
