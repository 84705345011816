import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { Row, Col, Modal, message, Button } from "antd";
import { addTermApi, updateTermApi, getTermsApi } from "../../APIs/TermsApis";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import {
  getAllPackagesApi,
  addPackageApi,
  editPackageApi,
  deletePackageApi,
} from "../../APIs/PackagesApis";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { BiWalletAlt } from "react-icons/bi";
import { AiFillCopy } from "react-icons/ai";

export const Rockets = () => {
  const [RocketCountPerUser, setRocketCountPerUser] = useState(0);
  const [RocketsDuration, setRocketsDuration] = useState(0);
  const [termID, settermID] = useState();
  const [addPackageOpen, setAddPackageOpen] = useState(false);
  const [editRocketsOpen, setEditRocketsOpen] = useState(false);
  const [editPackageOpen, setEditPackageOpen] = useState(false);
  const [confirmEditPackageOpen, setConfirmEditPackageOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [addRocketCount, setAddRocketCount] = useState("");
  const [addPrice, setAddPrice] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editRocketCount, setEditRocketCount] = useState("");
  const [editPackageObj, setEditPackageObj] = useState({});
  const [editPackageSentObj, setEditPackageSentObj] = useState({});
  const [deletePackageOpen, setDeletePackageOpen] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [addRocketCountError, setAddRocketCountError] = useState(false);
  const [addPriceError, setAddPriceError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getTermsApi().then((res) => {
      setRocketCountPerUser(res.data.data.defaultRocketsCount);
      setRocketsDuration(res.data.data.rocketPostsDuration);
      settermID(res.data.data._id);
    });
  }, []);

  const handleUpdateRocket = () => {
    const data = {
      defaultRocketsCount: RocketCountPerUser,
      rocketPostsDuration: RocketsDuration,
    };

    updateTermApi(termID, data)
      .then((res) => {
        message.success("تم التحديث بنجاح");
        setEditRocketsOpen(false);
      })
      .catch(() => {
        message.error("حدث خطأ ما  ");
      });
  };

  /// useEffects
  useEffect(() => {
    getAllPackagesFunc();
  }, []);

  /// get all packages func
  const getAllPackagesFunc = async () => {
    try {
      let response = await getAllPackagesApi("rocket");
      let data = await response?.data?.data;
      setPackages([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  /// add Package Func
  const addPackageFunc = async () => {
    if (addPrice == "" || addRocketCount == "") {
      if (addPrice == "") {
        setAddPriceError(true);
      }
      if (addRocketCount == "") {
        setAddRocketCountError(true);
      }
    } else {
      setLoading(true);
      try {
        let response = await addPackageApi({
          price: addPrice,
          rocketsCount: addRocketCount,
          type: "rocket",
        });
        message.success(response?.data?.message);
        setLoading(false);
        setAddPackageOpen(false);
        getAllPackagesFunc();
        setAddPrice("");
        setAddRocketCount("");
      } catch (error) {
        setLoading(false);
        let errorsArr = [];

        let errors = await error?.response?.data?.errors;
        errors.map((e) => {
          let error = e?.field ? e.field + "  " + e?.message : e?.message;
          errorsArr.push(error);
        });

        message.error(errorsArr.join());
      }
    }
  };

  /// edit package change handler

  const editPackageChange = (e) => {
    let { name, value } = e?.target;
    setEditPackageObj((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    switch (name) {
      case "price":
        setEditPrice(value);
        break;
      case "rocketCount":
        setEditRocketCount(value);
        break;

      default:
        break;
    }
    return editPackageObj;
  };

  const editPackageFunc = async () => {
    setConfirmEditPackageOpen(true);
    let obj = {};
    Object.keys(editPackageObj)?.forEach((key) => {
      const value = editPackageObj[key];
      if (value) {
        obj[key] = value;
      }
    });
    setEditPackageSentObj(obj);
  };

  /// confirm edit package
  const confirmEditPackageFunc = async () => {
    setLoading(true);
    try {
      let response = await editPackageApi(packageId, editPackageSentObj);
      message.success(response?.data?.message);
      setLoading(false);
      setConfirmEditPackageOpen(false);
      setEditPackageOpen(false);
      setEditPackageObj({});
      setEditPackageSentObj({});
      getAllPackagesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  /// delete package func
  const deletePackageFunc = async () => {
    setLoading(true);
    try {
      let response = await deletePackageApi(packageId);
      message.success(response?.data?.message);
      setLoading(false);
      setDeletePackageOpen(false);
      getAllPackagesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='pack-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الصواريخ</span>
          </p>
          <div className='flex items-center gap-2'>
            <button
              className='pack-add-pack-btn'
              onClick={() => {
                setAddPackageOpen(true);
              }}
            >
              إضافة باقة جديدة
            </button>
            <button
              className='pack-add-pack-btn'
              onClick={() => {
                setEditRocketsOpen(true);
              }}
            >
              إعدادت الصواريخ
            </button>
          </div>
        </div>
        <div className='pack-packages-div'>
          {packages?.map((pack) => (
            <div className='pack-packages-card'>
              <Row key={pack?.id}>
                <Col xs={12} style={{ fontSize: "20px" }}>
                  باقة {pack?.rocketsCount} صاروخ
                </Col>
                <Col xs={12}>
                  <div className='pack-icons-div'>
                    <span
                      className='pack-table-edit'
                      onClick={() => {
                        setEditPackageOpen(true);
                        setPackageId(pack?.id);
                        setEditPrice(pack?.price);
                        setEditRocketCount(pack?.rocketsCount);
                      }}
                    >
                      <BiEditAlt style={{ fontSize: "20px" }} />
                    </span>
                    <span
                      className='pack-table-delete'
                      onClick={() => {
                        setDeletePackageOpen(true);
                        setPackageId(pack?.id);
                      }}
                    >
                      <FaRegTrashAlt style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }} gutter={20}>
                <Col xs={4}>
                  <span className='pack-wallet-icon-span'>
                    <BiWalletAlt className='pack-wallet-icon' />
                  </span>
                </Col>
                <Col xs={20} className='pack-card-price'>
                  {pack?.price} ريال
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }} gutter={20}>
                <Col xs={2}>
                  <AiFillCopy
                    onClick={() => {
                      navigator.clipboard.writeText(pack?.id);
                      message.success("تم نسخ الرمز بنجاح");
                    }}
                    className='pack-wallet-icon cursor-pointer'
                  />
                </Col>
                <Col xs={20} style={{ color: "rgba(151, 151, 151, 1)" }}>
                  Product ID: {pack?.id}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
      {/***** add new package******/}
      <Modal
        title='إضافة باقة جديدة'
        className='pack-add-section-modal'
        open={addPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddPackageOpen(false);
          setAddPrice("");
          setAddRocketCount("");
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <input
            type='number'
            className='pack-add-pack-modal-input'
            placeholder=' عدد الصواريخ'
            value={addRocketCount}
            onChange={(e) => {
              setAddRocketCount(e.target.value);
              if (e.target.value !== "") {
                setAddRocketCountError(false);
              }
            }}
          />
          {addRocketCountError && (
            <p style={{ color: "red" }}>يجب ادخال عدد الصواريخ</p>
          )}
          <input
            type='number'
            className='pack-add-pack-modal-input'
            placeholder='سعر الباقة'
            style={{ marginTop: "15px" }}
            value={addPrice}
            onChange={(e) => {
              setAddPrice(e.target.value);
              if (e.target.value !== "") {
                setAddPriceError(false);
              }
            }}
          />
          {addPriceError && (
            <p style={{ color: "red" }}>يجب ادخال سعر الباقة</p>
          )}
          <Button
            className='pack-add-pack-modal-btn'
            onClick={addPackageFunc}
            loading={loading}
          >
            إضافة
          </Button>
        </div>
      </Modal>
      {/***** edit rockets bundles settings ******/}
      <Modal
        title='إعدادات باقات الصواريخ'
        className='pack-add-section-modal'
        open={editRocketsOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditRocketsOpen(false);
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <div className='sec-add-sec-modal-body flex flex-col gap-2 w-full !items-start'>
            <label htmlFor='rocketscount'>
              عدد الصواريخ المتاحه لكل مستخدم
            </label>
            <input
              type='text'
              name='rocketscount'
              className='sec-add-sec-modal-input'
              placeholder=' عدد الصواريخ المتاحه لكل مستخدم'
              value={RocketCountPerUser}
              onChange={(e) => {
                setRocketCountPerUser(e.target.value);
              }}
            />

            <label htmlFor='rocketscount' className='mt-4 -mb-4'>
              المده الزمنيه لتثبيت الصاروخ بالثواني
            </label>
            <input
              name='rocketsduration'
              type='text'
              className='sec-add-sec-modal-input my-4'
              placeholder='المده الزمنيه لتثبيت الصاروخ بالثواني'
              value={RocketsDuration}
              onChange={(e) => {
                setRocketsDuration(e.target.value);
              }}
            />

            <Button
              className='sec-add-sec-modal-btn'
              onClick={handleUpdateRocket}
            >
              تحديث
            </Button>
          </div>
        </div>
      </Modal>

      {/***** edit package******/}
      <Modal
        title='تعديل الباقة'
        className='pack-add-section-modal'
        open={editPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditPackageOpen(false);
          setEditPackageObj({});
          setEditPackageSentObj({});
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <label className='w-full text-right mb-3 mt-4'>عدد الصواريخ</label>
          <input
            type='number'
            name='rocketCount'
            className='pack-add-pack-modal-input'
            placeholder='عدد الصواريخ'
            value={editRocketCount}
            onChange={editPackageChange}
          />
          <label className='w-full text-right mb-3 mt-4'>سعر الباقة </label>
          <input
            type='number'
            name='price'
            className='pack-add-pack-modal-input'
            placeholder='سعر الباقة'
            value={editPrice}
            onChange={editPackageChange}
          />
          <label className='w-full text-right mb-3 mt-4'>ID المنتج</label>
          <div className='flex items-center w-full gap-2 '>
            <AiFillCopy
              className='size-5 cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(packageId);
                message.success("تم نسخ الرمز بنجاح");
              }}
            />
            <input
              disabled={true}
              name='id'
              className='pack-add-pack-modal-input text-gray-400'
              placeholder='package id'
              value={packageId}
            />
          </div>
          <div className='pack-edit-pack-modal-btns'>
            <button
              className='pack-edit-pack-modal-okBtn'
              onClick={editPackageFunc}
            >
              تعديل
            </button>
            <button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setEditPackageOpen(false);
                setEditPackageObj({});
                setEditPackageSentObj({});
              }}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>
      {/***** confirm edit package******/}
      <Modal
        title='تأكيد تعديل الباقة'
        className='pack-add-section-modal'
        open={confirmEditPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setConfirmEditPackageOpen(false);
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <div className='pack-edit-pack-modal-btns'>
            <Button
              className='pack-edit-pack-modal-okBtn'
              onClick={confirmEditPackageFunc}
              loading={loading}
            >
              تأكيد
            </Button>
            <Button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setConfirmEditPackageOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***** delete package******/}
      <Modal
        title=' حذف الباقة'
        className='pack-add-section-modal'
        open={deletePackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setDeletePackageOpen(false);
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <div className='pack-edit-pack-modal-btns'>
            <Button
              className='pack-edit-pack-modal-okBtn'
              onClick={deletePackageFunc}
              loading={loading}
            >
              حذف
            </Button>
            <Button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setDeletePackageOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
