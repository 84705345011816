import newRequest from "../utils/newRequest";

/// add rule
export const addRuleApi = (data) => {
  let result = newRequest.post("/base", data);
  return result;
};

/// get rules
export const getRulesApi = () => {
  let result = newRequest.get("/base");
  return result;
};

/// delete rule
export const deleteRuleApi = (id) => {
  let result = newRequest.delete(`/base/${id}`);
  return result;
};

/// update rule
export const updateRuleApi = (id, data) => {
  let result = newRequest.patch(`/base/${id}`, data);
  return result;
};

// get localized rules api
export const getLocalizedRulesApi = () => {
  let result = newRequest.get(`crm/base/`);
  return result;
};
