import "./guidelines.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import {
  addConditionApi,
  getAllInstructionsApi,
  updateInstructionApi,
  deleteInstructionApi,
} from "../../APIs/InstructionsApis";
import ReactQuill from "react-quill";
import { modules } from "../../utils/ReactQuillConfig";
import "react-quill/dist/quill.snow.css";
import { Modal, Button, message, Input } from "antd";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";

const { TextArea } = Input;

export const GuideLines = () => {
  const [loading, setLoading] = useState(false);
  const [guideLines, setguideLines] = useState();
  const [conditionID, setConditionID] = useState("");
  const [addNewTxtOpen, setAddNewTxtOpen] = useState(false);
  const [editTxtOpen, setEditTxtOpen] = useState(false);
  const [addTxt, setAddTxt] = useState("");
  const [editTxt, setEditTxt] = useState("");
  const [confirmAddCondition, setConfirmAddCondition] = useState(false);
  const [confirmDeleteCondition, setConfirmDeleteCondition] = useState(false);
  const [confirmEditConditionn, setConfirmEditCondition] = useState(false);
  const navigate = useNavigate();

  //// useEffects
  useEffect(() => {
    getConditionsFunc();
  }, []);
  /// add condition func
  const addConditionFunc = async () => {
    setLoading(true);
    try {
      let response = await addConditionApi({
        condition: addTxt,
      });
      setLoading(false);
      message.success(response?.data?.message);
      setConfirmAddCondition(false);
      setAddNewTxtOpen(false);
      setAddTxt("");
      getConditionsFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };
  /// get conditions func
  const getConditionsFunc = async () => {
    try {
      let response = await getAllInstructionsApi();
      let data = await response?.data?.data;
      setguideLines(data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  /// delete condition func
  const deleteConditionFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteInstructionApi({ conditionId: conditionID });
      setLoading(false);

      message.success("تم الحذف بنجاح");
      setConfirmDeleteCondition(false);
      getConditionsFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors?.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join() ?? "حدث خطأ ما");
    }
  };

  /// update condition func
  const updateConditionFunc = async () => {
    setLoading(true);
    try {
      let response = await updateInstructionApi({
        conditionId: conditionID,
        condition: editTxt,
      });
      setLoading(false);
      message.success(response?.data?.message);
      setConfirmEditCondition(false);
      setEditTxtOpen(false);
      getConditionsFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  return (
    <>
      <div className='conditions-main'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='conditions-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>إرشادات المجتمع</span>
          </p>
          <button
            className='conditions-add-conditions-btn'
            onClick={() => {
              setAddNewTxtOpen(true);
            }}
          >
            إضافة إرشاد جديد
          </button>
        </div>
        {guideLines?.conditions?.map((condition, index) => (
          <div className='conditions-content-div' key={index}>
            <div className='conditions-icons-div'>
              <span
                className='conditions-edit'
                onClick={() => {
                  setConditionID(condition?._id);
                  setEditTxtOpen(true);
                  setEditTxt(condition?.condition);
                }}
              >
                <BiEditAlt style={{ fontSize: "20px" }} />
              </span>
              <span
                className='conditions-delete'
                onClick={() => {
                  setConditionID(condition?._id);
                  setConfirmDeleteCondition(true);
                }}
              >
                <FaRegTrashAlt style={{ fontSize: "18px" }} />
              </span>
            </div>

            <div className='conditions-txt-div-container'>
              <div className='conditions-txt-div'>{condition.condition}</div>
            </div>
          </div>
        ))}
      </div>
      {/***** add new text******/}
      <Modal
        // title="تأكيد تعديل الباقة"
        className='conditions-add-conditions-modal'
        open={addNewTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddNewTxtOpen(false);
        }}
        footer={null}
        closable={false}
      >
        <div className='conditions-add-conditions-modal-body'>
          <label className='text'> الوصف: </label>
          <TextArea
            rows={4}
            style={{
              backgroundColor: "rgba(244, 244, 244, 1)",
              border: "none",
              boxShadow: "none",
            }}
            value={addTxt}
            onChange={(e) => {
              setAddTxt(e.target.value);
            }}
          />

          <div className='conditions-edit-conditions-modal-btns'>
            <Button
              className='conditions-edit-conditions-modal-okBtn'
              onClick={() => setConfirmAddCondition(true)}
            >
              إضافة
            </Button>
            <Button
              className='conditions-edit-conditions-modal-cancelBtn'
              onClick={() => {
                setAddNewTxtOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***** edit text******/}
      <Modal
        // title="تأكيد تعديل الباقة"
        className='conditions-add-conditions-modal'
        open={editTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditTxtOpen(false);
        }}
        footer={null}
      >
        <div className='conditions-add-conditions-modal-body'>
          <label className='text'> الوصف: </label>
          <TextArea
            rows={4}
            style={{
              backgroundColor: "rgba(244, 244, 244, 1)",
              border: "none",
              boxShadow: "none",
            }}
            value={editTxt}
            onChange={(e) => {
              setEditTxt(e.target.value);
            }}
          />

          <div className='conditions-edit-conditions-modal-btns'>
            <Button
              className='conditions-edit-conditions-modal-okBtn'
              onClick={() => setConfirmEditCondition(true)}
              //   loading={loading}
            >
              تعديل
            </Button>
            <Button
              className='conditions-edit-conditions-modal-cancelBtn'
              onClick={() => {
                setEditTxtOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/****confirm add condition modal** */}
      <ConfirmationModal
        title='تأكيد إضافة الإرشاد'
        open={confirmAddCondition}
        onCancel={() => setConfirmAddCondition(false)}
        onClick={addConditionFunc}
        loading={loading}
      />
      {/******confirm delete condition modal**** */}
      <ConfirmationModal
        title='تأكيد حذف الإرشاد'
        open={confirmDeleteCondition}
        onCancel={() => setConfirmDeleteCondition(false)}
        onClick={deleteConditionFunc}
        loading={loading}
      />
      {/*****confirm edit condition modal***** */}
      <ConfirmationModal
        title='تأكيد تعديل الإرشاد'
        open={confirmEditConditionn}
        onCancel={() => setConfirmEditCondition(false)}
        onClick={updateConditionFunc}
        loading={loading}
      />
    </>
  );
};
