import "./Ticket.css";
import { Row, Col, Modal, Input, message, Table, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { FaClosedCaptioning, FaRegTrashAlt } from "react-icons/fa";
import newRequest from "../../utils/newRequest";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { MdClose } from "react-icons/md";

export const Ticket = () => {
  const [answerTicketToggle, setAnswerTicketToggle] = useState(false);
  const { TextArea } = Input;
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldRefetch, setShouldRefetch] = useState(false);
  const [page, setPage] = useState(1);
  const [answer, setAnswer] = useState("");
  const [status, setStatus] = useState("");
  const [singleTicket, setSingleTicket] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 20,
  });
  const navigate = useNavigate();

  useEffect(() => {
    getTickets();
    setShouldRefetch(false);
  }, [page, shouldRefetch]);

  ///get tickets func
  const getTickets = async () => {
    setLoading(true);
    try {
      const res = await newRequest.get(`ticket?page=${page}&limit=10`);
      setTickets(res?.data?.data);
      setTickets(res?.data?.data);
      const currentPage = res?.data?.pagination?.currentPage;
      const totalPages = res?.data?.pagination?.totalPages;
      const resultCount = res?.data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };
  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "اسم المستخدم",
      dataIndex: "user",
      render: (user) => user?.userName,
      className: "center-align",
    },
    {
      title: "البريد الالكرتوني",
      dataIndex: "user",
      render: (user) => user?.email,
      className: "center-align",
    },
    {
      title: "عنوان التذكره",
      dataIndex: "ticketTitle",
      className: "center-align",
    },
    {
      title: " المحتوي",
      dataIndex: "description",
      className: "center-align",
    },
    {
      title: "الاجراء",
      key: "action",
      className: "center-align",
      render: (_, record) => (
        <div className='tick-table-icons-div'>
          <span
            className='tick-table-answer'
            onClick={() => {
              setSingleTicket(record);
              setAnswerTicketToggle(true);
            }}
          >
            رد
          </span>
          <span
            className='tick-table-delete'
            onClick={() => hnadleDelete(record._id)}
          >
            <FaRegTrashAlt />
          </span>
        </div>
      ),
    },
  ];

  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };

  const hnadleDelete = async (_id) => {
    try {
      setLoading(true);
      await newRequest.delete(`ticket/${_id}`);
      setLoading(false);
      setShouldRefetch(true);
    } catch (error) {
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const handleAnswer = async () => {
    if (!status || !answer) {
      return message.warning("برجاء ملئ جميع الحقول");
    }
    try {
      setLoading(true);
      const res = await newRequest.put(`ticket/${singleTicket._id}`, {
        answer,
        status,
      });

      setLoading(false);
      setAnswerTicketToggle(false);
      message.success(res?.data?.message);
    } catch (error) {
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  return (
    <>
      <div
        className='tick-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div className='tick-title-div'>
          <p className='sec-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>التذكرة</span>
          </p>
        </div>
        <div
          className='tick-table'
          style={{ padding: answerTicketToggle ? "0px" : "0px 0px 10px" }}
        >
          {answerTicketToggle ? (
            <>
              <div>
                <span
                  className='tick-table-delete-div'
                  onClick={() => {
                    setAnswerTicketToggle(false);
                    setStatus("");
                    setAnswer("");
                  }}
                >
                  <MdClose style={{ fontSize: "18px" }} />
                </span>
                <div className='tick-answer-ticket-div'>
                  <div className='tick-img-div'>
                    {" "}
                    <img src='./profile.svg' className='tick-img' alt='' />
                  </div>
                  <div className='tick-answer-body'>
                    <p className='tick-top-txt'>
                      <span style={{ color: "rgba(151, 151, 151, 1)" }}>
                        اسم المستخدم :
                      </span>
                      <span>{singleTicket?.user?.userName}</span>
                    </p>
                    <p className='tick-top-txt'>
                      <span style={{ color: "rgba(151, 151, 151, 1)" }}>
                        البريد الالكترونى :
                      </span>{" "}
                      <span> {singleTicket?.user?.email}</span>
                    </p>
                    <p className='tick-top-txt'>
                      <span style={{ color: "rgba(151, 151, 151, 1)" }}>
                        رقم الجوال :
                      </span>{" "}
                      <span> {singleTicket?.user?.phoneNumber?.number}</span>
                    </p>
                    <p className='tick-txtarea-title'>
                      {singleTicket?.ticketTitle}
                    </p>
                    <Select
                      defaultValue='الحالة'
                      style={{ width: 320 }}
                      onChange={(value) => setStatus(value)}
                      options={[
                        {
                          value: "accepted",
                          label: "Accepted",
                        },
                        {
                          value: "rejected",
                          label: "Rejected",
                        },
                      ]}
                    />
                    <TextArea
                      rows={4}
                      placeholder='الرد علي التذكرة لابد ان يحتوي علي 8 حروف علي الاقل'
                      style={{ marginTop: "10px" }}
                      minLength={8}
                      onChange={(e) => setAnswer(e.target.value)}
                    />
                    <button className='tick-send-btn' onClick={handleAnswer}>
                      إرسال
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <Table
                loading={loading}
                columns={columns}
                dataSource={tickets}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
