import "./Reports.css";
import { Row, Col, Modal, Table, message, Select } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { getReportsApi, deleteReportApi } from "../../APIs/reportsApis";
import { formatDateDayMonthYear } from "../../utils/time";
import { answerReport } from "../../APIs/reportsApis";

export const Reports = () => {
  const [warningPopupOpen, setWarningPopupOpen] = useState(false);
  // const [answers, setAnswers] = useState(true);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [answer, setAnswer] = useState("");
  const [reportType, setReportType] = useState("");
  const [reportStatus, setReportStatus] = useState("");
  const [status, setStatus] = useState("");
  const [refetch, setRefetch] = useState(false);
  const [singleTicket, setSingleTicket] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 20,
  });

  const [selectedReport, setselectedReport] = useState(null);
  const [reason, setReason] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    getReports();
  }, [page, refetch, reportType, reportStatus]);

  /// get reports search queries
  const getSearchQueries = () => {
    let x = "";
    if (reportType !== "") {
      x += `&type=${reportType}`;
    }
    if (reportStatus !== "") {
      x += `&status=${reportStatus}`;
    }
    return x;
  };

  /// get reports func
  const getReports = async () => {
    setLoading(true);
    try {
      const searchResult = getSearchQueries();
      const res = await getReportsApi(page, searchResult);
      let arr = res?.data?.data.filter((ele) => ele?.user);
      setReports(arr);
      console.log(arr);
      const currentPage = res?.data?.pagination?.currentPage;
      const resultCount = res?.data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
      setLoading(false);
      setRefetch(false);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };

  const handleBlock = async () => {
    try {
      await answerReport(selectedReport._id, reason);
      message.success("sucesss");

      setWarningPopupOpen(false);
      setReason("");
      setReports(reports.filter((ele) => ele._id !== selectedReport._id));
    } catch (error) {
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "اسم المستخدم",
      dataIndex: "user",
      render: (user) => user?.userName,
      className: "center-align",
    },
    {
      title: "البريد الالكرتوني",
      dataIndex: "user",
      render: (user) => user?.email,
      className: "center-align",
    },

    {
      title: "",
      key: "action",
      className: "center-align",
      render: (_, record) => (
        <div className='tick-table-icons-div flex p-0 !justify-end'>
          <span
            className='tick-table-delete '
            onClick={() => {
              setWarningPopupOpen(true);
              setselectedReport(record);
            }}
          >
            <TiTick />
          </span>
        </div>
      ),
    },

    {
      title: "",
      key: "action",
      className: "center-align",
      render: (_, record) => (
        <div className='tick-table-icons-div flex p-0 !justify-start'>
          <span
            className='tick-table-delete !p-0 !m-0'
            onClick={() => hnadleDelete(record._id)}
          >
            <FaRegTrashAlt />
          </span>
        </div>
      ),
    },
  ];

  const hnadleDelete = async (_id) => {
    try {
      setLoading(true);
      await deleteReportApi(_id);
      setLoading(false);
      // setRefetch(true);
      getReports();
    } catch (error) {
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='repo-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>
            <span>البلاغات</span>
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {/* <button 
           
            onClick={()=>setReportType("post")}
                        className={reportType==="post"?"reportClicked":"reportUnClicked"}>الأسئلة</button>
            <button 
           
            onClick={()=>setReportType("comment")}
                        className={reportType==="comment"?"reportClicked":"reportUnClicked"}
            >الأجوبة</button> */}
          <Select
            placeholder={"النوع"}
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              width: "40%",
            }}
            // name="userJobTitle"
            // className="sett-select-menu"

            onChange={(e) => setReportType(e)}
            options={[
              { value: "post", label: <span>الأسئلة</span> },
              { value: "comment", label: <span>الأجوبة</span> },
              { value: "", label: <span>الكل</span> },
            ]}
          />
          <Select
            placeholder={"الحالة"}
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              width: "40%",
            }}
            // name="userJobTitle"
            // className="sett-select-menu"

            onChange={(e) => setReportStatus(e)}
            options={[
              { value: "pending", label: <span>لم يتم الرد عليها</span> },
              { value: "completed", label: <span>تم الرد عليها</span> },
              { value: "", label: <span>الكل</span> },
            ]}
          />
        </div>
        <Table
          loading={loading}
          columns={columns}
          dataSource={reports}
          pagination={pagination}
          onChange={handleTableChange}
          // onRow={(record) => ({
          //   onClick: () => {
          //     setWarningPopupOpen(true);
          //   },
          // })}
        />
      </div>

      {/***** Report popup Modal ******/}
      <Modal
        title='بلاغات'
        className='sec-add-section-modal'
        open={warningPopupOpen}
        onOk={() => {}}
        onCancel={() => setWarningPopupOpen(false)}
        footer={null}
      >
        <div className='sec-add-sec-modal-body'>
          <div
            className='w-full'
            style={{
              padding: "15px",
              borderBottom: "1px solid lightgray",
              borderRadius: "10px",
            }}
          >
            <div
              className='main-admin'
              style={{
                display: "flex",
              }}
            >
              <img
                src='./profile.svg'
                style={{
                  width: "50px",
                  height: "50px",
                  borderRadius: "50%",
                  lineHeight: "100px",
                  boxShadow: "0 0 4px gray",
                }}
                alt=''
              />
              <div
                className='main-admin-data'
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  marginRight: "20px",
                }}
              >
                <p style={{ fontSize: "16px", fontWeight: "600" }}>
                  {selectedReport?.user.userName}{" "}
                </p>
                <p
                  style={{
                    fontSize: "16px",
                    color: "rgba(172, 172, 172, 1)",
                    fontWeight: "600",
                  }}
                >
                  {formatDateDayMonthYear(selectedReport?.createdAt)}
                </p>
              </div>
            </div>
            <p style={{ marginTop: "10px" }}>
              {selectedReport?.post && (
                <div>{selectedReport?.post.description}</div>
              )}
              {selectedReport?.comment && (
                <div>{selectedReport?.comment.comment}</div>
              )}
            </p>
          </div>
          <div className='form-control my-8'>
            <input
              type='text'
              placeholder=' سبب الحظر'
              minLength={4}
              name='reason'
              onChange={(e) => setReason(e.target.value)}
              value={reason}
            />
          </div>
          <button onClick={handleBlock} className='sec-add-sec-modal-btn'>
            حظر
          </button>
        </div>
      </Modal>
    </>
  );
};
