import { useGlobalContextState } from "../context/GlobalContext";

export const UsePermission = () => {
  const GlobalContextState = useGlobalContextState();
  function hasPermission(permission) {
    return (
      GlobalContextState.permissions.includes("admin") ||
      GlobalContextState.permissions.includes(permission)
    );
  }
  return { hasPermission };
};
