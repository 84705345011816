import { Button, message } from "antd";
import { useState } from "react";
import { IoMdEyeOff } from "react-icons/io";
import { IoEye } from "react-icons/io5";
import { ConfirmationModal } from "../../../Components/Modals/ConfirmationModal";
import { usePostData } from "../../../Hooks/useAxios";
import "./DeleteUserAccount.css";

export const DeleteUserAccount = (props) => {
  const [forgetPassword, setForgetPassword] = useState("login");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmDeleteSectionOpen, setConfirmDeleteSectionOpen] =
    useState(false);
  const { mutate, isPending } = usePostData(
    false,
    () => {
      message.success("تم ارسال الطلب بنجاح, سيتم الحذف بعد 30 يوم من الان!");
      setConfirmDeleteSectionOpen(false);
    },
    false,
    (error) => {
      message.error(
        error?.response?.data?.errors?.[0]?.message || "حدث خطأ ما"
      );
      setConfirmDeleteSectionOpen(false);
    }
  );

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setConfirmDeleteSectionOpen(true);
  };
  const DeleteAccount = () => {
    mutate({
      api: process.env.REACT_APP_DELETE_USER_ACCOUNT,
      data: { email, password },
      method: "DELETE",
    });
  };

  return (
    <>
      <div className="login-wrapper">
        <div className="login-form">
          <form onSubmit={handleSubmit} action="post">
            <img src="./askMeLogo.png" className="login-logo" alt="logo" />
            <p
              style={{ marginTop: "10px", fontSize: "20px", fontWeight: "600" }}
            >
              {forgetPassword === "login"
                ? "حذف الحساب"
                : "استرجاع كلمة المرور"}
            </p>

            <input
              type="email"
              name="loginEmail"
              className="login-inputs"
              placeholder="البريد الالكترونى"
              style={{ marginTop: "30px", width: "100%" }}
              title="This field should not be left blank."
              required
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              onInvalid={(e) =>
                e.target.setCustomValidity("برجاء ادخال البريد الالكترونى")
              }
              onInput={(e) => e.target.setCustomValidity("")}
            />
            {forgetPassword === "login" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  position: "relative",
                  marginTop: "5px",
                }}
              >
                <input
                  type={passwordVisible ? "text" : "password"}
                  value={password}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="كلمة المرور "
                  name="loginPassword"
                  className="login-inputs"
                  style={{ width: "100%" }}
                  onInvalid={(e) =>
                    e.target.setCustomValidity("برجاء ادخال كلمة المرور ")
                  }
                  onInput={(e) => e.target.setCustomValidity("")}
                />
                {passwordVisible ? (
                  <IoEye
                    onClick={togglePasswordVisibility}
                    className="login-password-eye-icon"
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={togglePasswordVisibility}
                    className="login-password-eye-icon"
                  />
                )}
              </div>
            ) : null}

            <p
              style={{
                textDecoration:
                  forgetPassword === "login" ? "underline" : "none",
                width: "100%",
                marginTop: "10px",
                fontSize: "18px",
                fontWeight: "600",
                cursor: "pointer",
              }}
              onClick={() => {
                if (forgetPassword === "login") {
                  setForgetPassword("forget");
                }
              }}
            >
              {forgetPassword === "login"
                ? "نسيت كلمة المرور"
                : "لاسترجاع كلمة المرور قم بادخال البريد الالكترونى لارسال كود التفعيل عليه"}
            </p>
            <Button type="primary" className="login-btn" htmlType="submit">
              {forgetPassword === "login" ? "حذف الحساب" : "إرسال"}
            </Button>
          </form>
        </div>{" "}
        <ConfirmationModal
          title="تأكيد حذف الحساب"
          open={confirmDeleteSectionOpen}
          onClick={DeleteAccount}
          loading={isPending}
          onCancel={() => {
            setConfirmDeleteSectionOpen(false);
          }}
          //loading={loading}
        />
      </div>
    </>
  );
};
