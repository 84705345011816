import "./Banner.css";
import React, { useState, useEffect } from "react";
import { Row, Col, Modal, message, Button } from "antd";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { RiImageAddLine } from "react-icons/ri";
import { BiEditAlt } from "react-icons/bi";
import newRequest from "../../utils/newRequest";

export const Banner = () => {
  const [addBannerOpen, setAddBannerOpen] = useState(false);
  const [sentEditImage, setSentEditImage] = useState(null);
  const [photo, setPhoto] = useState(null);

  const [banner, setBanner] = useState("");
  const [confirmAddBannerOpen, setConfirmAddBannerOpen] = useState(false);
  const [confirmEditBannerOpen, setConfirmEditBannerOpen] = useState(false);
  const [confirmDeleteBannerOpen, setConfirmDeleteBannerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [banners, setBanners] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getBanners();
  }, []);

  const getBanners = async () => {
    try {
      const res = await newRequest.get("/slider");
      setBanners(res?.data?.data?.images);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!photo) return;
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("images", photo);
      const res = await newRequest.put("/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setLoading(false);
      setPhoto(null);
      setBanners(res?.data?.data?.images);
      setAddBannerOpen(false);
      setConfirmAddBannerOpen(false);
    } catch (error) {
      setLoading(false);
      setAddBannerOpen(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const handleDelete = async () => {
    if (!banner) return;
    //console.log([banner])
    setLoading(true);
    try {
      const formData = new FormData();
      [banner].forEach((value, index) => {
        formData.append(`currentImages[0]`, value);
      });
      // const arr = JSON.stringify([banner]);
      //formData.append("currentImages[0]", banner);

      const res = await newRequest.put("/slider", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      message.success(res?.data?.message);
      // setBanners(res?.data?.data?.images);
      getBanners();
      setLoading(false);
      setConfirmDeleteBannerOpen(false);
    } catch (error) {
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='banner-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>البنر الرئيسى</span>
          </p>
          <button
            className='banner-add-banner-btn'
            onClick={() => {
              setAddBannerOpen(true);
            }}
          >
            إضافة بنر رئيسى جديد
          </button>
        </div>
        <div className='pack-packages-div'>
          {banners?.map((banner, index) => (
            <div
              className='pack-packages-card'
              style={{
                position: "relative",
                width: "fit-content",
                height: "270px",
                minWidth: "fit-content",
              }}
              key={index}
            >
              {/* <span
                className="pack-table-delete"
                style={{ position: "absolute", top: "20px", left: "10px" }}
                onClick={() => handleDelete(banner)}
              >
                <FaRegTrashAlt style={{ fontSize: "18px" }} />
              </span> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "15px",
                  left: "10px",
                }}
              >
                <span
                  className='sec-table-delete'
                  onClick={() => {
                    setConfirmDeleteBannerOpen(true);
                    setBanner(banner);
                  }}
                >
                  <FaRegTrashAlt style={{ fontSize: "18px" }} />
                </span>
              </div>
              <img
                src={process.env.REACT_APP_ASSETS_BASE_URL + banner}
                style={{ width: "228px", height: "250px" }}
                className='object-cover'
                alt=''
              />
            </div>
          ))}
        </div>
      </div>

      {/***** add intro Modal ******/}
      <Modal
        title='إضافة بنر رئيسى جديد'
        className='intro-add-intro-modal'
        open={addBannerOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddBannerOpen(false);
          setPhoto(null);
        }}
        footer={null}
      >
        <div className='intro-add-intro-modal-body'>
          <div>
            <label htmlFor='uploadInput' className='relative'>
              {photo && (
                <img
                  src={
                    typeof photo === "string"
                      ? photo
                      : URL.createObjectURL(photo)
                  }
                  alt='Uploaded'
                  accept='image/*'
                  className='intro-add-modal-img absolute bottom-0 z-40 pointer-events-none'
                />
              )}
              <div className='intro-add-modal-avatar'>
                <input
                  type='file'
                  id='uploadInput'
                  accept='image/*'
                  style={{ display: "none" }}
                  onChange={(e) => {
                    setPhoto(e.target.files[0]);
                  }}
                />
                <RiImageAddLine
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "rgba(69, 120, 175, 1)",
                  }}
                />
              </div>
            </label>
          </div>

          <p className='banner-add-modal-txt'>يجب أن يكون حجم الصورة 172*335</p>
          <div className='intro-edit-intro-modal-btns'>
            {/* <button
              className="intro-add-intro-modal-okBtn"
              onClick={handleSubmit}
            >
              إضافة
            </button> */}
            <Button
              onClick={() => setConfirmAddBannerOpen(true)}
              className='intro-add-intro-modal-okBtn'
            >
              إضافة
            </Button>

            <button
              className='intro-add-intro-modal-cancelBtn'
              onClick={() => {
                setAddBannerOpen(false);

                setPhoto(null);
              }}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>

      {/****confirm add intro modal*** */}
      <ConfirmationModal
        title='تأكيد إضافة بنر رئيسى جديد'
        open={confirmAddBannerOpen}
        onCancel={() => setConfirmAddBannerOpen(false)}
        loading={loading}
        onClick={handleSubmit}
      />

      {/****confirm delete intro modal*** */}
      <ConfirmationModal
        title='تأكيد حذف البنر '
        open={confirmDeleteBannerOpen}
        onCancel={() => setConfirmDeleteBannerOpen(false)}
        loading={loading}
        onClick={handleDelete}
      />
    </>
  );
};
