import newRequest from "../utils/newRequest";

// sendEmail api
export const sendEmail = async (data) => {
  try {
    const res = await newRequest.post("/user/email", data);
    return res.data;
  } catch (error) {
    return error.response.data;
  }
};
