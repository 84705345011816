import "./Privacy.css";
import { useEffect, useState } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Modal, Button, message } from "antd";
import {
  addTermApi,
  updateTermApi,
  getLocalizedTermsApi,
} from "../../APIs/TermsApis";
import { modules } from "../../utils/ReactQuillConfig";

export const AdminPrivacy = () => {
  const [addNewTxtOpen, setAddNewTxtOpen] = useState(false);
  const [editTxtOpen, setEditTxtOpen] = useState(false);
  const [addTxt, setAddTxt] = useState("");
  const [addTxtEn, setAddTxtEn] = useState("");
  const [editTxt, setEditTxt] = useState("");
  const [editTxtEn, setEditTxtEn] = useState("");
  const [privacy, setPrivacy] = useState({});
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getPrivacy();
  }, [refetch]);
  ///getPrivacy func
  const getPrivacy = async () => {
    try {
      const res = await getLocalizedTermsApi();

      setPrivacy(res?.data?.data);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const handleCreate = async () => {
    if (privacy.privacy) {
      return message.warning("لا يمكن اضافة اكثر من سياسة");
    }
    setLoading(true);
    try {
      const res = await addTermApi({ privacy: { en: addTxtEn, ar: addTxt } });
      setPrivacy(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  const handleUpdate = async () => {
    try {
      const res = await updateTermApi(privacy._id, {
        privacy: {
          en: editTxtEn,
          ar: editTxt,
        },
      });
      setPrivacy(res?.data?.data);
      setEditTxtOpen(false);
    } catch (error) {
      error
        ? error?.response?.data?.errors.map((error) =>
            message.error(error.message)
          )
        : message.error("Server Error Please Try Again Later !");
    }
  };

  return (
    <>
      <div className='privacy-main'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='privacy-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>سياسة الخصوصية</span>
          </p>
          {!privacy && (
            <button
              className='privacy-add-privacy-btn'
              onClick={() => {
                setAddNewTxtOpen(true);
              }}
            >
              إضافة نص جديد
            </button>
          )}
        </div>
        <div className='privacy-content-div'>
          <div className='privacy-icons-div'>
            {privacy && (
              <span
                className='privacy-edit'
                onClick={() => {
                  setEditTxtOpen(true);
                  setEditTxt(privacy?.privacy?.ar);
                  setEditTxtEn(privacy?.privacy?.en);
                }}
              >
                <BiEditAlt style={{ fontSize: "20px" }} />
              </span>
            )}
            {/* <span
              className="privacy-delete"
              // onClick={() => deleteSectionFunc(cat?.id)}
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </span> */}
          </div>
          <h1 className='text-2xl'>العربية:</h1>
          <div
            className='rules-txt-div'
            dangerouslySetInnerHTML={{
              __html: privacy?.privacy?.ar ?? "لا يوجد نص لعرضه بعد",
            }}
          />
          <h1 className='text-2xl text-left mt-12'>:English</h1>
          <div
            className='rules-txt-div text-left'
            dangerouslySetInnerHTML={{
              __html: privacy?.privacy?.en ?? "No content to display yet",
            }}
          />
        </div>
      </div>
      {/***** add new text******/}
      <Modal
        className='privacy-add-privacy-modal'
        open={addNewTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddNewTxtOpen(false);
        }}
        footer={null}
      >
        <div className='privacy-add-privacy-modal-body'>
          <h1 className='text-2xl my-2'>العربية:</h1>
          <ReactQuill value={addTxt} onChange={setAddTxt} modules={modules} />
          <h1 className='text-2xl my-2'>:English</h1>
          <ReactQuill
            value={addTxtEn}
            onChange={setAddTxtEn}
            modules={modules}
            className='text-left'
          />
          <div className='privacy-edit-privacy-modal-btns'>
            <Button
              className='privacy-edit-privacy-modal-okBtn'
              onClick={handleCreate}
              loading={loading}
            >
              إضافة
            </Button>
            <Button
              className='privacy-edit-privacy-modal-cancelBtn'
              onClick={() => {
                setAddNewTxtOpen(false);
                setAddTxt("");
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***** edit text******/}
      <Modal
        className='privacy-add-privacy-modal'
        open={editTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditTxtOpen(false);
        }}
        footer={null}
      >
        <div className='privacy-add-privacy-modal-body'>
          <h1 className='text-2xl my-2'>العربية:</h1>
          <ReactQuill value={editTxt} onChange={setEditTxt} modules={modules} />
          <h1 className='text-2xl my-2'>:English</h1>
          <ReactQuill
            value={editTxtEn}
            onChange={setEditTxtEn}
            modules={modules}
          />
          <div className='privacy-edit-privacy-modal-btns'>
            <Button
              className='privacy-edit-privacy-modal-okBtn'
              onClick={handleUpdate}
              //   loading={loading}
            >
              تعديل
            </Button>
            <Button
              className='privacy-edit-privacy-modal-cancelBtn'
              onClick={() => {
                setEditTxtOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
