import newRequest from "../utils/newRequest";

/// get reports
export const getReportsApi = (page) => {
  let result = newRequest.get(`report?page=${page}&status=pending`);
  return result;
};

/// delete report
export const deleteReportApi = (_id) => {
  let result = newRequest.delete(`report/${_id}`);
  return result;
};

// answer report api
export const answerReport = async (reportID, reason) => {
  const result = newRequest.post(`/report/${reportID}`, {
    status: {
      value: "blocked",
      reason: reason,
    },
  });
  return result;
};
