import React, { useState, useEffect } from "react";
import { Routes, Outlet, Route, useNavigate, Navigate } from "react-router-dom";
import { Header } from "./Components/Header/Header";
import { Sidebar } from "./Components/Sidebar/Sidebar";
import { Main } from "./Pages/Main/Main";
import { Login } from "./Pages/Login/Login";
import { Sections } from "./Pages/Sections/Sections";
import { Layout } from "antd";
import "./App.css";
import { Packages } from "./Pages/Packages/Packages";
import { Invoice } from "./Pages/Invoice/Invoice";
import { Reports } from "./Pages/Reports/Reports";
import { Ticket } from "./Pages/Ticket/Ticket";
import { Banner } from "./Pages/Banner/Banner";
import { AdminPrivacy } from "./Pages/AdminPrivacy/AdminPrivacy";
import { AdminTerms } from "./Pages/AdminTerms/AdminTerms";
import { Introduction } from "./Pages/Introduction/Introduction";
import Settings from "./Pages/Settings/Settings";
import { Rules } from "./Pages/Rules/Rules";
import { Users } from "./Pages/Users/Users";
import { Questions } from "./Pages/Questions/Questions";
import { useGlobalContextState } from "./context/GlobalContext";
import ProtectedRoutes from "./Components/ProtectionComponents/ProtectedRoutes";
import { InappropriateWords } from "./Pages/InappropriateWords/InappropriateWords";
import { Countries } from "./Pages/Countries/Countries";
import PublicPrivacy from "./Pages/Public/PublicPrivacy/PublicPrivacy";
import { DeleteUserAccount } from "./Pages/Public/DeleteUserAccount/DeleteUserAccount";
import NickNames from "./Pages/NickNames/nicknames.js";
import { Rockets } from "./Pages/Rocket/rocket.js";
import SendEmail from "./Pages/email/email.jsx";
import { GuideLines } from "./Pages/GuideLines/guidelines.js";
import NotFoundPage from "./Pages/404/index.jsx";
import SendNotification from "./Pages/notifications/notifications.jsx";
import SharedPost from "./Pages/share/share.jsx";
import { Features } from "./Pages/Features/features.js";

function App() {
  const { Content } = Layout;
  const GlobalContextState = useGlobalContextState();

  const [loggedUserName, setLoggedUserName] = useState("");
  const [headerUserImage, setHeaderUserImage] = useState("");
  const navigate = useNavigate();

  const updateLoggedUserName = (value) => {
    setLoggedUserName(value);
  };

  const updateHeaderUserImage = (value) => {
    setHeaderUserImage(value);
  };

  const DashboardLayout = React.memo(() => {
    return (
      <>
        <Sidebar className='sideBar' />
        <Layout className='content'>
          <Content>
            <div className='container'>
              <Header
                loggedUserName={loggedUserName}
                userImage={headerUserImage}
              />

              <div className='contentDiv'>
                <Outlet />
              </div>
            </div>
          </Content>
        </Layout>
      </>
    );
  });

  return (
    <>
      <Layout
        style={{
          position: "absolute",
          // height: "100vh",
          minHeight: "100vh",
          width: "100%",
          display: "flex",
        }}
      >
        <Routes>
          <Route
            path='/login'
            element={
              localStorage.getItem("user") ? (
                <Navigate to={"/main"} replace />
              ) : (
                <Login
                  updateUserName={updateLoggedUserName}
                  updateUserImage={updateHeaderUserImage}
                />
              )
            }
          />

          <Route path='/our-privacy' element={<PublicPrivacy />} />
          <Route path='/delete-account' element={<DeleteUserAccount />} />

          {GlobalContextState.GetRoleIsPending === false && (
            <>
              {" "}
              <Route element={<DashboardLayout />}>
                <Route index element={<Main />} />
                <Route path='/main' element={<Main />} />
                <Route
                  path='/users'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/users"}>
                      <Users />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/questions'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/questions"}>
                      <Questions />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/bad-words'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/bad-words"}>
                      <InappropriateWords />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/sections'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/sections"}>
                      <Sections />
                    </ProtectedRoutes>
                  }
                />
                <Route path='/packages' element={<Packages />} />
                <Route
                  path='/invoice'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/invoice"}>
                      <Invoice />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/reports'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/reports"}>
                      <Reports />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/settings'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/settings"}>
                      <Settings />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/ticket'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/ticket"}>
                      <Ticket />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/banner'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/banner"}>
                      <Banner />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/intro'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/intro"}>
                      <Introduction />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/privacy'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/privacy"}>
                      <AdminPrivacy />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/features'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/features"}>
                      <Features />
                    </ProtectedRoutes>
                  }
                />
                {/* <Route
          path='/terms-and-conditions'
          element={
            <ProtectedRoutes
              redirectPath={`/`}
              path={("/privacy")}
            >
              <AdminTerms />
            </ProtectedRoutes>
          }
        /> */}
                <Route
                  path='/countries'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/countries"}>
                      <Countries />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/rules'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/rules"}>
                      <Rules />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/nicknames'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/nicknames"}>
                      <NickNames />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/rocket'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/rocket"}>
                      <Rockets />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/email'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/email"}>
                      <SendEmail />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/notify'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/notify"}>
                      <SendNotification />
                    </ProtectedRoutes>
                  }
                />
                <Route
                  path='/guidlines'
                  element={
                    <ProtectedRoutes redirectPath={`/`} path={"/guidlines"}>
                      <GuideLines />
                    </ProtectedRoutes>
                  }
                />
              </Route>
              <Route path='*' element={<NotFoundPage />} />
            </>
          )}
          <Route path='/share/:id' element={<SharedPost />} />
          <Route path='/' element={<Login />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
      </Layout>
    </>
  );
}

export default App;
