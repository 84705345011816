import React from "react";
import { Modal,Button } from "antd";



export const ConfirmationModal = (props)=>{
   
    return(<>
    <Modal
        title={props?.title}
        open={props?.open}
        onCancel={props?.onCancel}
        footer={null}
        
      >
        
        <div className="intro-edit-intro-modal-btns" style={{marginTop:"100px"}}>
            <Button
              className="intro-add-intro-modal-okBtn"
              loading={props?.loading}
              onClick={props?.onClick}
            >
              تأكيد
            </Button>
            <Button
              className="intro-add-intro-modal-cancelBtn"
              onClick={props?.onCancel}
            >
              إلغاء
            </Button>
          </div>
      </Modal>
    </>)
}
