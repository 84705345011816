import React from "react";
import { Row, Col } from "antd";
import { FaSearch } from "react-icons/fa";

import "./Search.css";

function SearchBox(props) {
  // let { myfunc } = props;
  return (
    <div className="search_block">
      <Row className="searchRow">
        <Col span={2} className="searchIconCol">
          <FaSearch className="search_icon" />
        </Col>
        <Col span={22} className="searchInputCol">
          <input
            type="search"
           // placeholder="Search in Employee name"
            className="search_input"
            // onChange={props.onchange}
            // value={props.state}
          />
        </Col>
      </Row>
    </div>
  );
}

export default SearchBox;
