import React from "react";
import { PUBLIC } from "../../../utils/Queries";
import { useFetchData } from "../../../Hooks/useAxios";
import Logo from "../../../Components/MainComponents/Logo";
import { PiSpinnerLight } from "react-icons/pi";

function PublicPrivacy() {
  const { data, isError, isPaused, isPending } = useFetchData({
    identifier: PUBLIC.PRIVACY,
    api: process.env.REACT_APP_GET_PUBLIC_PRIVACY,
    select: true,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  return (
    <section className="py-10 bg-bg  w-full">
      <section
        className="flex-col flex items-center justify-between min-h-screen gap-10 bg-white w-3/4 sm:w-10/12 mx-auto p-10 rounded-2xl
      "
      >
        <Logo />
        {isError || isPaused ? (
          <div className=" min-h-96 flex-center">
            <p className="text-center text-red-500">
              حدث خطأ في التحميل, الرجاء المحاولة مرة اخرى!
            </p>
          </div>
        ) : isPending ? (
          <div className=" min-h-96 flex-center">
            <PiSpinnerLight className="animate-spin text-primary size-14" />
          </div>
        ) : (
          <div
            className="w-full text-justify first-line:font-bold first-line:text-xl"
            dangerouslySetInnerHTML={{ __html: data?.privacy }}
          ></div>
        )}
        <p className="text-center">
          © {new Date().getFullYear()} جميع الحقوق محفوظة لشركة{" "}
          <a
            className="text-primary"
            target="_blank"
            rel="noreferrer"
            href="https://asusapps.com/"
          >
            أسس الحلول لتقنية المعلومات
          </a>
        </p>
      </section>
    </section>
  );
}

export default PublicPrivacy;
