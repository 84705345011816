import "./Rules.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import {
  addRuleApi,
  getLocalizedRulesApi,
  deleteRuleApi,
  updateRuleApi,
} from "../../APIs/RulesApis";
import ReactQuill from "react-quill";
import { modules } from "../../utils/ReactQuillConfig";
import "react-quill/dist/quill.snow.css";
import { Modal, Button, message } from "antd";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";

export const Rules = () => {
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [ruleId, setRuleId] = useState("");
  const [addNewTxtOpen, setAddNewTxtOpen] = useState(false);
  const [editTxtOpen, setEditTxtOpen] = useState(false);
  const [addTxt, setAddTxt] = useState("");
  const [addTxtEn, setAddTxtEn] = useState("");
  const [editTxt, setEditTxt] = useState("");
  const [editTxtEn, setEditTxtEn] = useState("");
  const [confirmAddRule, setConfirmAddRule] = useState(false);
  const [confirmDeleteRule, setConfirmDeleteRule] = useState(false);
  const [confirmEditRule, setConfirmEditRule] = useState(false);
  const navigate = useNavigate();

  //// useEffects
  useEffect(() => {
    getRulesFunc();
  }, []);
  /// add rule func
  const addRuleFunc = async () => {
    setLoading(true);
    try {
      let response = await addRuleApi({
        desc: {
          ar: addTxt,
          en: addTxtEn,
        },
      });
      setLoading(false);
      message.success(response?.data?.message);
      setConfirmAddRule(false);
      setAddNewTxtOpen(false);
      setAddTxt("");
      getRulesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };
  /// get rules func
  const getRulesFunc = async () => {
    try {
      let response = await getLocalizedRulesApi();
      let data = await response?.data?.data;
      setRules([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };
  /// delete rule func
  const deleteRuleFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteRuleApi(ruleId);
      setLoading(false);

      message.success(response?.data?.message);
      setConfirmDeleteRule(false);
      getRulesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  /// update rule func
  const updateRuleFunc = async () => {
    setLoading(true);
    try {
      let response = await updateRuleApi(ruleId, {
        desc: {
          ar: editTxt,
          en: editTxtEn,
        },
      });
      setLoading(false);
      message.success(response?.data?.message);
      setConfirmEditRule(false);
      setEditTxtOpen(false);
      getRulesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  return (
    <>
      <div className='rules-main'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='rules-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>القواعد</span>
          </p>
          <button
            className='rules-add-rules-btn'
            onClick={() => {
              setAddNewTxtOpen(true);
            }}
          >
            إضافة قاعدة جديدة
          </button>
        </div>
        {rules?.map((rule, index) => (
          <div className='rules-content-div' key={index}>
            <div className='rules-icons-div'>
              <span
                className='rules-edit'
                onClick={() => {
                  setRuleId(rule?._id);
                  setEditTxtOpen(true);
                  setEditTxt(rule?.desc?.ar);
                  setEditTxtEn(rule?.desc?.en);
                }}
              >
                <BiEditAlt style={{ fontSize: "20px" }} />
              </span>
              <span
                className='rules-delete'
                onClick={() => {
                  setRuleId(rule?._id);
                  setConfirmDeleteRule(true);
                }}
              >
                <FaRegTrashAlt style={{ fontSize: "18px" }} />
              </span>
            </div>

            <div className='rules-txt-div-container'>
              <div
                className='rules-txt-div'
                dangerouslySetInnerHTML={{ __html: rule?.desc?.ar }}
              ></div>

              <div
                className='rules-txt-div mt-8 text-left'
                dangerouslySetInnerHTML={{ __html: rule?.desc?.en }}
              ></div>
            </div>
          </div>
        ))}
      </div>
      {/***** add new text******/}
      <Modal
        // title="تأكيد تعديل الباقة"
        className='rules-add-rules-modal'
        open={addNewTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddNewTxtOpen(false);
        }}
        footer={null}
        closable={false}
      >
        <div className='rules-add-rules-modal-body'>
          <h1 className='text-2xl my-2'>العربية:</h1>
          <ReactQuill value={addTxt} onChange={setAddTxt} modules={modules} />
          <h1 className='text-2xl my-2'>:English</h1>
          <ReactQuill
            value={addTxtEn}
            onChange={setAddTxtEn}
            modules={modules}
          />
          <div className='rules-edit-rules-modal-btns'>
            <Button
              className='rules-edit-rules-modal-okBtn'
              onClick={() => setConfirmAddRule(true)}
            >
              إضافة
            </Button>
            <Button
              className='rules-edit-rules-modal-cancelBtn'
              onClick={() => {
                setAddNewTxtOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***** edit text******/}
      <Modal
        // title="تأكيد تعديل الباقة"
        className='rules-add-rules-modal'
        open={editTxtOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditTxtOpen(false);
        }}
        footer={null}
      >
        <div className='rules-add-rules-modal-body'>
          <h1 className='text-2xl my-2'>العربية:</h1>
          <ReactQuill value={editTxt} onChange={setEditTxt} modules={modules} />
          <h1 className='text-2xl my-2'>:English</h1>
          <ReactQuill
            value={editTxtEn}
            onChange={setEditTxtEn}
            modules={modules}
          />
          <div className='rules-edit-rules-modal-btns'>
            <Button
              className='rules-edit-rules-modal-okBtn'
              onClick={() => setConfirmEditRule(true)}
              //   loading={loading}
            >
              تعديل
            </Button>
            <Button
              className='rules-edit-rules-modal-cancelBtn'
              onClick={() => {
                setEditTxtOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/****confirm add rule modal** */}
      <ConfirmationModal
        title='تأكيد إضافة القاعدة'
        open={confirmAddRule}
        onCancel={() => setConfirmAddRule(false)}
        onClick={addRuleFunc}
        loading={loading}
      />
      {/******confirm delete rule modal**** */}
      <ConfirmationModal
        title='تأكيد حذف القاعدة'
        open={confirmDeleteRule}
        onCancel={() => setConfirmDeleteRule(false)}
        onClick={deleteRuleFunc}
        loading={loading}
      />
      {/*****confirm edit rule modal***** */}
      <ConfirmationModal
        title='تأكيد تعديل القاعدة'
        open={confirmEditRule}
        onCancel={() => setConfirmEditRule(false)}
        onClick={updateRuleFunc}
        loading={loading}
      />
    </>
  );
};
