import newRequest from "../utils/newRequest";

///get categories
export const getAllCategoriesApi = () => {
  let result = newRequest.get("/crm/category?limit=999999");
  return result;
};

/// add section

export const addSectionApi = (data) => {
  let result = newRequest.post("/category", data);
  return result;
};

/// edit section

export const editSectionApi = (id, data) => {
  let result = newRequest.put(`/category/${id}`, data);
  return result;
};

/// delete section

export const deleteSectionApi = (id) => {
  let result = newRequest.delete(`/category/${id}`);
  return result;
};
