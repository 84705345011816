import React, { useEffect, useState } from "react";
import "./Introduction.css";
import { assetsURL } from "../../APIs/MainApis";
import { useNavigate } from "react-router-dom";
import {
  getLocalizedIntrosApi,
  addIntroApi,
  deleteIntroApi,
  editIntroApi,
} from "../../APIs/IntroductionApis";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { Row, Col, Modal, Input, message } from "antd";
import { RiImageAddLine } from "react-icons/ri";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";

export const Introduction = () => {
  const [introductions, setIntroductions] = useState([]);
  const [addIntroOpen, setAddIntroOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [imageUploaded, setImageUploaded] = useState(null);
  const [addImageError, setAddImageError] = useState(false);
  const [addIntroError, setAddIntroError] = useState(false);
  const [editIntroOpen, setEditIntroOpen] = useState(false);
  const [uploadedEditedPhoto, setUploadedEditedPhoto] = useState(null);
  const [editedSentPhoto, setEditedSentPhoto] = useState(null);
  const [introData, setIntroData] = useState("");
  const [editIntroData, setEditIntroData] = useState("");
  const [introDataEn, setIntroDataEn] = useState("");
  const [editIntroDataEn, setEditIntroDataEn] = useState("");
  const [confirmAddIntroOpen, setConfirmAddIntroOpen] = useState(false);
  const [confirmEditIntroOpen, setConfirmEditIntroOpen] = useState(false);
  const [confirmDeleteIntroOpen, setConfirmDeleteIntroOpen] = useState(false);
  const [boardNum, setBoardNum] = useState("");

  const [addIntroObj, setAddIntroObj] = useState({});
  const [editIntroObj, setEditIntroObj] = useState({});
  const [introLoading, setIntroLoading] = useState(false);
  const [introId, setIntroId] = useState("");
  const { TextArea } = Input;
  const navigate = useNavigate();
  /// useEffects
  useEffect(() => {
    getIntroductionsFunc();
  }, []);

  /// get introductions
  const getIntroductionsFunc = async () => {
    try {
      let response = await getLocalizedIntrosApi();
      let data = await response?.data?.data;
      setIntroductions([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  /// add introduction func
  const addIntroFunc = async () => {
    setIntroLoading(true);
    try {
      let response = await addIntroApi(addIntroObj);
      console.log(response);
      setIntroLoading(false);
      setConfirmAddIntroOpen(false);
      setAddIntroOpen(false);
      getIntroductionsFunc();
      setImageUrl(null);
      setIntroData("");
      getIntroductionsFunc();
    } catch (error) {
      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        //errorsArr.push(error);
        message.error(error);
      });

      //message.error(errorsArr.join());
      setIntroLoading(false);
    }
  };
  /// edit introduction func
  const editIntroFunc = async () => {
    setIntroLoading(true);

    try {
      let response = await editIntroApi(introId, editIntroObj);

      setIntroLoading(false);
      setConfirmEditIntroOpen(false);
      setEditIntroOpen(false);
      message.success(response?.data?.message);
      getIntroductionsFunc();
      setImageUrl(null);
      setEditIntroData("");
      setEditIntroDataEn("");
      getIntroductionsFunc();
    } catch (error) {
      //console.log(error);
      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errors.push(error);
      });
      message.error("حدث خطأ ما يرجي المحاولة مرة أخري");
      message.error(errors.join());
      setIntroLoading(false);
    }
  };
  /// delete intro func
  const deleteIntroFunc = async () => {
    setIntroLoading(true);
    try {
      let response = await deleteIntroApi(introId);
      console.log("delete", response);
      message.success("تم الحذف بنجاح");
      setIntroLoading(false);
      setConfirmDeleteIntroOpen(false);

      getIntroductionsFunc();
    } catch (e) {
      message.error("لم يتم الحذف");
      setIntroLoading(false);
    }
  };
  const handleUpload = (e) => {
    setAddImageError(false);
    const file = e.target.files[0];
    // console.log("image", file);
    setImageUploaded(file);
    const reader = new FileReader();
    //reader.readAsDataURL(file);
    reader.onload = (e) => {
      setImageUrl(e.target.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <>
      <div className='intro-main'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='intro-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>المقدمة</span>
          </p>
          <button
            className='intro-add-intro-btn'
            onClick={() => {
              setAddIntroOpen(true);
            }}
          >
            إضافة مقدمة جديدة
          </button>
        </div>
        <div className='intro-cards-div'>
          {introductions?.map((intro, index) => (
            <div className='intro-card' key={intro?.id}>
              {/* <span className="intro-card-delete-span" onClick={()=>{
                  setConfirmDeleteIntroOpen(true)
                  setIntroId(intro?.id)}}>
                <FaRegTrashAlt />
              </span> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  top: "15px",
                  left: "10px",
                }}
              >
                <span
                  className='sec-table-edit'
                  onClick={() => {
                    setEditIntroOpen(true);
                    setImageUrl(assetsURL + intro?.image);
                    setBoardNum(intro?.boardNum);
                    setIntroId(intro?._id);
                    setEditIntroData(intro?.title.ar);
                    setEditIntroDataEn(intro?.title.en);
                  }}
                >
                  <BiEditAlt style={{ fontSize: "20px" }} />
                </span>
                <span
                  className='sec-table-delete'
                  onClick={() => {
                    setConfirmDeleteIntroOpen(true);
                    setIntroId(intro?.id);
                  }}
                >
                  <FaRegTrashAlt style={{ fontSize: "18px" }} />
                </span>
              </div>
              <img
                alt='intro-img'
                src={process.env.REACT_APP_ASSETS_BASE_URL + intro?.image}
              />
              <p className='my-2'>{intro?.title?.ar}</p>
              <p className='my-2'>{intro?.title?.en}</p>
            </div>
          ))}
        </div>
        <p className='intro-bottom-txt'>
          أقصى عدد من المقدمات يمكن إضافتها 3 مقدمات
        </p>
      </div>

      {/***** add intro Modal ******/}
      <Modal
        title='إضافة مقدمة جديدة'
        className='intro-add-intro-modal'
        open={addIntroOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddIntroOpen(false);
          setImageUrl(null);
          setImageUploaded(null);
          setIntroData("");
          setAddImageError(false);
          setAddIntroError(false);
        }}
        footer={null}
      >
        <div className='intro-add-intro-modal-body'>
          <div>
            <label htmlFor='uploadInput' className='relative'>
              {imageUrl && (
                <img
                  src={imageUrl}
                  alt=''
                  className='intro-add-modal-img z-40 absolute pointer-events-none'
                />
              )}
              <div className='intro-add-modal-avatar'>
                <input
                  type='file'
                  id='uploadInput'
                  accept='image/*'
                  style={{ display: "none" }}
                  onChange={handleUpload}
                />
                <RiImageAddLine
                  style={{
                    width: "70px",
                    height: "70px",
                    color: "rgba(69, 120, 175, 1",
                  }}
                />
              </div>
            </label>
          </div>

          {addImageError && !imageUrl ? (
            <p style={{ color: "red" }}>من فضلك اختر صورة</p>
          ) : (
            ""
          )}
          <p className='intro-add-modal-txt'>يفضل أن يكون حجم الصورة 172*335</p>
          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-col gap-1'>
              <label> المحتوي باللغة العربية </label>
              <TextArea
                rows={4}
                style={{
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  border: "none",
                  boxShadow: "none",
                }}
                value={introData}
                onChange={(e) => {
                  setIntroData(e.target.value);
                }}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <label> Content in English</label>
              <TextArea
                rows={4}
                style={{
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  border: "none",
                  boxShadow: "none",
                }}
                value={introDataEn}
                onChange={(e) => {
                  setIntroDataEn(e.target.value);
                }}
              />
            </div>
          </div>

          {addIntroError && !introData && !introDataEn ? (
            <p style={{ color: "red" }}>من فضلك ادخل المقدمة</p>
          ) : (
            ""
          )}
          <div className='intro-edit-intro-modal-btns'>
            <button
              className='intro-add-intro-modal-okBtn'
              onClick={() => {
                if (introData && imageUrl) {
                  setConfirmAddIntroOpen(true);
                  setAddIntroObj({
                    "title[en]": introDataEn,
                    "title[ar]": introData,
                    image: imageUploaded,
                    boardNum: introductions?.length + 1,
                  });
                } else {
                  setAddIntroError(true);
                  setAddImageError(true);
                }
              }}
            >
              إضافة
            </button>
            <button
              className='intro-add-intro-modal-cancelBtn'
              onClick={() => {
                setAddIntroOpen(false);
                setImageUrl(null);
                setImageUploaded(null);
                setIntroData("");
                setAddImageError(false);
                setAddIntroError(false);
              }}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>
      {/***** edit intro Modal ******/}
      <Modal
        title='تعديل المقدمة '
        className='intro-add-intro-modal'
        open={editIntroOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditIntroOpen(false);
          setImageUrl(null);
          setImageUploaded(null);
          setEditIntroData("");
        }}
        footer={null}
      >
        <div className='intro-add-intro-modal-body'>
          <div style={{ position: "relative" }}>
            {/* ) : ( */}
            <div style={{ top: "0", left: "0" }}>
              <label htmlFor='uploadInput' className='relative'>
                {/* {imageUrl ? ( */}
                <img
                  src={imageUrl}
                  alt=''
                  className='intro-add-modal-img z-40 absolute top-0'
                />
                <div className='intro-add-modal-avatar'>
                  <input
                    type='file'
                    id='uploadInput'
                    accept='image/*'
                    style={{ display: "none" }}
                    onChange={handleUpload}
                  />
                  <RiImageAddLine
                    style={{
                      width: "70px",
                      height: "70px",
                      color: "gray",
                    }}
                  />
                </div>
              </label>
            </div>
            {/* )} */}
          </div>

          <p className='intro-add-modal-txt'>يفضل أن يكون حجم الصورة 172*335</p>

          <div className='flex flex-col gap-2 w-full'>
            <div className='flex flex-col gap-1'>
              <label> المحتوي باللغة العربية </label>
              <TextArea
                rows={4}
                style={{
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  border: "none",
                  boxShadow: "none",
                }}
                value={editIntroData}
                onChange={(e) => {
                  setEditIntroData(e.target.value);
                }}
              />
            </div>
            <div className='flex flex-col gap-1'>
              <label> Content in English</label>
              <TextArea
                rows={4}
                style={{
                  backgroundColor: "rgba(244, 244, 244, 1)",
                  border: "none",
                  boxShadow: "none",
                }}
                value={editIntroDataEn}
                onChange={(e) => {
                  setEditIntroDataEn(e.target.value);
                }}
              />
            </div>
          </div>

          <div className='intro-edit-intro-modal-btns'>
            <button
              className='intro-add-intro-modal-okBtn'
              onClick={() => {
                if (imageUploaded) {
                  setEditIntroObj({
                    "title[en]": editIntroData,
                    "title[ar]": editIntroDataEn,
                    image: imageUploaded,
                    boardNum: boardNum,
                  });
                } else {
                  setEditIntroObj({
                    "title[en]": editIntroData,
                    "title[ar]": editIntroDataEn,

                    boardNum: boardNum,
                  });
                }
                setConfirmEditIntroOpen(true);

                // if (introData && imageUrl) {
                //   setConfirmAddIntroOpen(true);
                //   setAddIntroObj({
                //     title: introData,
                //     image: imageUploaded,
                //     boardNum: introductions?.length + 1,
                //   });
                // } else {
                // setAddIntroError(true);
                //   setAddImageError(true);
                // }
              }}
            >
              تعديل
            </button>
            <button
              className='intro-add-intro-modal-cancelBtn'
              onClick={() => {
                setEditIntroOpen(false);
                setImageUrl(null);
                setImageUploaded(null);
                setEditIntroData("");
              }}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>
      {/****confirm add intro modal*** */}
      <ConfirmationModal
        title='تأكيد إضافة المقدمة'
        open={confirmAddIntroOpen}
        onCancel={() => setConfirmAddIntroOpen(false)}
        loading={introLoading}
        onClick={addIntroFunc}
      />
      {/****confirm edit intro modal*** */}
      <ConfirmationModal
        title='تأكيد تعديل المقدمة'
        open={confirmEditIntroOpen}
        onCancel={() => setConfirmEditIntroOpen(false)}
        loading={introLoading}
        onClick={editIntroFunc}
      />
      {/****confirm delete intro modal*** */}
      <ConfirmationModal
        title='تأكيد حذف المقدمة'
        open={confirmDeleteIntroOpen}
        onCancel={() => setConfirmDeleteIntroOpen(false)}
        loading={introLoading}
        onClick={deleteIntroFunc}
      />
    </>
  );
};
