import newRequest from "../utils/newRequest";
import Axios from "axios";

/// get introductions
export const getIntroductionsApi = () => {
  let result = newRequest.get("/board");
  return result;
};

/// create intro
export const addIntroApi = (data) => {
  const formData = new FormData();

  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });

  let result = newRequest.post("/board", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result;
};
/// update intro
export const editIntroApi = (id, data) => {
  const formData = new FormData();

  Object.keys(data)?.forEach((key) => {
    formData.append(key, data[key]);
  });

  let result = newRequest.put(`/board/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return result;
};

/// delete introduction

export const deleteIntroApi = (id) => {
  let result = newRequest.delete(`/board/${id}`);
  return result;
};

// get localized introductions
export const getLocalizedIntrosApi = () => {
  let result = newRequest.get(`/crm/board/`);
  return result;
};
