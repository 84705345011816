import { useEffect, useState } from "react";
import "./Users.css";
import { getAllUsersApi, deleteUserApi } from "../../APIs/UsersApis";
import { FaRegTrashAlt } from "react-icons/fa";
import { Table, message } from "antd";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [deleteUserOpen, setDeleteUserOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 20,
  });
  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "اسم المستخدم",
      dataIndex: "userName",
      //  render: (user) => user?.userName,
      className: "center-align",
    },
    {
      title: "البريد الالكرتوني",
      dataIndex: "email",
      // render: (user) => user?.email,
      className: "center-align",
    },

    {
      //   title: "الاجراء",
      key: "action",
      className: "center-align",
      render: (_, record, index) => (
        <div className='tick-table-icons-div'>
          {/* <span
            className="tick-table-answer"
            onClick={() => {
              setSingleTicket(record);
              setAnswerTicketToggle(true);
            }}
          >
            رد
          </span> */}
          <span
            className='tick-table-delete'
            onClick={() => {
              setUserId(record?.id);
              setDeleteUserOpen(true);
            }}
          >
            <FaRegTrashAlt />
          </span>
        </div>
      ),
    },
  ];

  /// useEffects
  useEffect(() => {
    getAllUsersFunc();
  }, [page]);

  /// get all users func
  const getAllUsersFunc = async () => {
    setLoading(true);
    try {
      let res = await getAllUsersApi({ page });
      console.log("users", res?.data?.data);
      setUsers(res?.data?.data);
      setLoading(false);
      const currentPage = res?.data?.pagination?.currentPage;
      const totalPages = res?.data?.pagination?.totalPages;
      const resultCount = res?.data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
    } catch (e) {
      setLoading(false);
    }
  };

  /// handle page change
  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };
  //delete user func
  const deleteUserFunc = async () => {
    setLoading(true);
    try {
      let response = await deleteUserApi(userId);
      setLoading(false);
      setDeleteUserOpen(false);
      message.success(response?.data?.message);
      getAllUsersFunc();
    } catch (error) {
      setLoading(false);
      console.log(error);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e.field ? e.field + " " + e.message : e.message;
        errorsArr.push(error);
      });
      message.error(errorsArr.join());
    }
  };

  return (
    <>
      <div className='main-main'>
        <p>
          <span className='main-control-txt'>لوحة التحكم /</span>{" "}
          <span style={{ fontSize: "18px" }}>المستخدمين</span>
        </p>
        <div
          className='tick-table'
          // style={{ padding: "0px 0px 10px" }}
        >
          <Table
            loading={loading}
            columns={columns}
            dataSource={users}
            pagination={pagination}
            onChange={handleTableChange}
          />
        </div>
      </div>
      <ConfirmationModal
        title='تأكيد حذف الموظف'
        open={deleteUserOpen}
        loading={loading}
        onCancel={() => setDeleteUserOpen(false)}
        onClick={deleteUserFunc}
      />
    </>
  );
};
