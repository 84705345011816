import { useEffect, useReducer, useState } from "react";
import "./Countries.css";
import { FaRegTrashAlt } from "react-icons/fa";
import { Button, Modal, Table, message } from "antd";
import { ConfirmationModal } from "../../Components/Modals/ConfirmationModal";
import { useFetchData, usePostData } from "../../Hooks/useAxios";
import { ADMIN } from "../../utils/Queries";
import { BiEditAlt } from "react-icons/bi";
import CitiesTable from "./CitiesTable";
import AddStateToCountryAction from "./AddStateToCountryAction";
import { UsePermission } from "../../Hooks/UsePermission";

export const Countries = () => {
  const initialState = {
    deletedCountryId: "",
    toggleDeleteCountryModal: false,
    updatedCountryId: "",
    currentOperation: "",
  };

  const ReducerFunction = (state, action) => {
    switch (action.type) {
      case "SetDeletedCountryId":
        return {
          ...state,
          deletedCountryId: action.payload,
        };

      case "SetToggleDeleteCountryModal":
        return {
          ...state,
          toggleDeleteCountryModal: action.payload,
        };
      case "SetResetDeleteCountryConfirmationModal":
        return {
          ...state,
          deletedCountryId: "",
          toggleDeleteCountryModal: false,
        };
      case "SetUpdatedCountryId":
        return {
          ...state,
          updatedCountryId: action.payload,
        };
      case "SetCurrentOperation":
        return {
          ...state,
          currentOperation: action.payload,
        };
      default:
        throw Error("Unknown action: " + action.type);
    }
  };

  const [state, dispatch] = useReducer(ReducerFunction, initialState);
  const { data, error, isPending, refetch } = useFetchData({
    identifier: ADMIN.GET_COUNTRIES,
    api: process.env.REACT_APP_COUNTRY,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
  });
  const { data: countryDetails } = useFetchData({
    identifier: ADMIN.GET_COUNTRY_DETAILS,
    api: `${process.env.REACT_APP_COUNTRY}/${state?.updatedCountryId}`,
    cacheTime: 60 * 1000,
    staleTime: 60 * 1000,
    enabled: !!state?.updatedCountryId,
    select: true,
    id: state?.updatedCountryId,
  });
  useEffect(() => {
    if (countryDetails) {
      setCountryArName(countryDetails?.name?.ar);
      setCountryEnName(countryDetails?.name?.en);
    }
  }, [countryDetails]);

  const { mutate: AddCountry, isPending: AddCountryIsPending } = usePostData(
    true,
    () => {
      setToggleAddCountryModal(false);
      setCountryArName("");
      setCountryEnName("");
      refetch();
    }
  );
  const { mutate: DeleteCountry, isPending: DeleteCountryIsPending } =
    usePostData(true, () => {
      dispatch({ type: "SetResetDeleteCountryConfirmationModal" });
      refetch();
    });

  const { mutate: UpdateCountry, isPending: UpdateCountryIsPending } =
    usePostData(true, () => {
      setToggleAddCountryModal(false);
      setCountryArName("");
      setCountryEnName("");
      refetch();
    });

  const [ToggleAddCountryModal, setToggleAddCountryModal] = useState(false);
  const [countryArName, setCountryArName] = useState("");
  const [countryArNameError, setCountryArNameError] = useState("");
  const [countryEnName, setCountryEnName] = useState("");
  const [countryEnNameError, setCountryEnNameError] = useState("");
  const { hasPermission } = UsePermission();
  const [countries, setCountries] = useState([]);
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 20,
  });
  const columns = [
    {
      dataIndex: "_id",
      key: "_id",
      title: "#",
      render: (text, record, index) => index + 1,
      className: "center-align",
    },
    {
      title: "الدولة",
      dataIndex: "name",
      className: "center-align",
    },
    {
      key: "action",
      className: "center-align",
      render: (_, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {hasPermission("create state") && (
            <AddStateToCountryAction country={record} />
          )}{" "}
          {hasPermission("update country") && (
            <span
              className="sec-table-edit"
              onClick={() => {
                dispatch({ type: "SetCurrentOperation", payload: "update" });
                dispatch({ type: "SetUpdatedCountryId", payload: record?._id });
                setToggleAddCountryModal(true);
              }}
            >
              <BiEditAlt style={{ fontSize: "20px" }} />
            </span>
          )}
          {hasPermission("delete country") && (
            <span
              className="sec-table-delete"
              onClick={() => {
                dispatch({ type: "SetDeletedCountryId", payload: record?._id });
                dispatch({
                  type: "SetToggleDeleteCountryModal",
                  payload: true,
                });
              }}
            >
              <FaRegTrashAlt style={{ fontSize: "18px" }} />
            </span>
          )}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      setCountries(data?.data?.map((e) => ({ ...e, key: e?._id })));
      const currentPage = data?.pagination?.currentPage;
      const totalPages = data?.pagination?.totalPages;
      const resultCount = data?.pagination?.resultCount;
      setPagination({
        current: currentPage,
        pageSize: 10,
        total: resultCount,
      });
    }
  }, [data]);

  /// handle page change
  const handleTableChange = (paginationData) => {
    setPage(paginationData.current);
    setPagination({
      ...paginationData,
    });
  };

  const deleteCountryFunc = () => {
    DeleteCountry({
      api: `${process.env.REACT_APP_COUNTRY}/${state.deletedCountryId}`,
      method: "DELETE",
    });
  };
  const addUpdateCountryFunc = () => {
    if (countryArName === "") {
      setCountryArNameError(true);
    } else if (countryEnName === "") {
      setCountryEnNameError(true);
    } else {
      if (state.currentOperation === "update") {
        UpdateCountry({
          api: `${process.env.REACT_APP_COUNTRY}/${state.updatedCountryId}`,
          data: {
            name: {
              ar: countryArName,
              en: countryEnName,
            },
          },
          method: "PATCH",
        });
      } else {
        AddCountry({
          api: process.env.REACT_APP_COUNTRY,
          data: {
            name: {
              ar: countryArName,
              en: countryEnName,
            },
          },
        });
      }
    }
  };

  return (
    <>
      <div className="main-main">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className="sec-top-txt">
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الدول والمدن</span>
          </p>
          {hasPermission("cretae country") && (
            <button
              className="sec-add-sec-btn"
              onClick={() => {
                setToggleAddCountryModal(true);
              }}
            >
              اضافه دولة
            </button>
          )}
        </div>
        <div className="tick-table">
          <Table
            loading={isPending}
            columns={columns}
            dataSource={countries}
            pagination={pagination}
            onChange={handleTableChange}
            expandable={{
              expandedRowRender: (country) => (
                <CitiesTable key={country?._id} country={country} />
              ),
            }}
          />
        </div>
      </div>{" "}
      <Modal
        title={
          state.currentOperation === "update"
            ? "تعديل الدولة "
            : "إضافة دولة جديدة"
        }
        className="sec-add-section-modal"
        open={ToggleAddCountryModal}
        onOk={() => {}}
        onCancel={() => {
          setToggleAddCountryModal(false);
          setCountryArName("");
          setCountryEnName("");
          dispatch({
            type: "SetCurrentOperation",
            payload: "",
          });
          dispatch({ type: "SetUpdatedCountryId", payload: "" });
        }}
        footer={null}
      >
        <div className="sec-add-sec-modal-body space-y-5">
          <label className="w-full" htmlFor="ar_name">
            <input
              id="ar_name"
              type="text"
              className="sec-add-sec-modal-input"
              placeholder="اسم الدولة باللغة بالعربية"
              value={countryArName}
              onChange={(e) => {
                setCountryArName(e.target.value);
                if (e.target.value !== "") {
                  setCountryArNameError(false);
                }
              }}
            />
            {countryArNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم الدولة باللغة بالعربية{" "}
              </p>
            )}
          </label>
          <label className="w-full" htmlFor="en_name">
            <input
              id="en_name"
              type="text"
              dir="ltr"
              className="sec-add-sec-modal-input"
              placeholder="اسم الدولة باللغة الانجليزية"
              value={countryEnName}
              onChange={(e) => {
                setCountryEnName(e.target.value);
                if (e.target.value !== "") {
                  setCountryEnNameError(false);
                }
              }}
            />
            {countryEnNameError && (
              <p style={{ color: "red" }}>
                من فضلك ادخل اسم الدولة باللغة الانجليزية
              </p>
            )}
          </label>
          <Button
            className="sec-add-sec-modal-btn"
            onClick={addUpdateCountryFunc}
            loading={AddCountryIsPending || UpdateCountryIsPending}
          >
            {state.currentOperation === "update" ? "تعديل  " : "إضافة"}
          </Button>
        </div>
      </Modal>
      <ConfirmationModal
        title="تأكيد حذف الدولة"
        open={state.toggleDeleteCountryModal}
        loading={DeleteCountryIsPending}
        onCancel={() =>
          dispatch({ type: "SetResetDeleteCountryConfirmationModal" })
        }
        onClick={deleteCountryFunc}
      />
    </>
  );
};
