import newRequest from "../utils/newRequest";

/// add role
export const addRoleApi = (data) => {
  let result = newRequest.post("/role", data);
  return result;
};

// get all roles
export const getAllRolesApi = () => {
  let result = newRequest.get("/role");
  return result;
};

// get role by id
export const getRoleByIdApi = (id) => {
  let result = newRequest.get(`/role/${id}`);
  return result;
};

// update role
export const updateRoleApi = (id, data) => {
  let result = newRequest.put(`/role/${id}`, data);
  return result;
};

// delete role
export const deleteRoleApi = (id) => {
  let result = newRequest.delete(`/role/${id}`);
  return result;
};

// get permissions list
export const getPermissionsListApi = () => {
  let result = newRequest.get("/role/permission");
  return result;
};
