import { sendEmail } from "../../APIs/EmailsApis";
import ReactQuill from "react-quill";
import { useState } from "react";
import { Button, message } from "antd";
import "./styles.css";

const modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    [
      { header: "1" },
      { header: "2" },
      { header: [3, 4, 5, 6] },
      { header: [false] },
    ],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    [{ align: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
  history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true,
  },
};

const SendEmail = () => {
  const [emailTitle, setemailTitle] = useState("");
  const [emailBody, setemailBody] = useState("");

  const [sendingLoading, setsendingLoading] = useState(false);

  const handleSendingMail = () => {
    setsendingLoading(true);
    sendEmail({ subject: emailTitle, html: emailBody })
      .then((res) => {
        setsendingLoading(false);
        message.success(res?.data?.message ?? "تم الإرسال بنجاح");
        setemailTitle("");
        setemailBody("");
      })
      .catch(async (error) => {
        setsendingLoading(false);
        let errorsArr = [];

        let errors = await error?.response?.data?.errors;
        errors.map((e) => {
          let error = e.field + " " + e.message;
          errorsArr.push(error);
        });
        message.error(errorsArr.join() ?? "حدث خطأ ما");
      });
  };

  return (
    <>
      {" "}
      <p className='privacy-top-txt mt-4 mr-4'>
        <span style={{ color: "rgba(151, 151, 151, 1)" }} className='mt-4'>
          لوحة التحكم /
        </span>{" "}
        <span> إرسال بريد إلكتروني </span>
      </p>
      <div className='flex flex-col w-fit items-center justify-center mx-auto my-6 p-8 rounded-[5px] gap-8 bg-white '>
        <h1 className='text-2xl'>إرسال بريد إلكتروني لجميع المستخدمين</h1>

        <div className='flex flex-col gap-2 w-[600px] max-w-[60vw]'>
          <label> العنوان </label>
          <input
            type='text'
            className='email-input'
            placeholder='عنوان البريد الإلكتروني'
            value={emailTitle}
            onChange={(e) => {
              setemailTitle(e.target.value);
            }}
          />
        </div>
        <div className='flex flex-col gap-2'>
          <label> المحتوي </label>
          <ReactQuill
            className='w-[600px]  max-w-[60vw]'
            value={emailBody}
            onChange={setemailBody}
            modules={modules}
          />
        </div>
        <Button
          className='sec-add-sec-modal-btn !w-[200px] !-mt-2 disabled:!cursor-default'
          onClick={handleSendingMail}
          loading={sendingLoading}
          disabled={!emailBody || !emailTitle}
        >
          إرسال
        </Button>
      </div>
    </>
  );
};

export default SendEmail;
