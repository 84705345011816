import React, { useState, useEffect } from "react";
import "./Packages.css";
import { useNavigate } from "react-router-dom";
import {
  getAllPackagesApi,
  addPackageApi,
  editPackageApi,
  deletePackageApi,
} from "../../APIs/PackagesApis";
import { Row, Col, Modal, message, Button } from "antd";
import { FaRegTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { BiWalletAlt } from "react-icons/bi";
import { formatSecondsToHoursArabic } from "../../utils/time";
import { AiFillCopy } from "react-icons/ai";

export const Packages = () => {
  const [addPackageOpen, setAddPackageOpen] = useState(false);
  const [editPackageOpen, setEditPackageOpen] = useState(false);
  const [confirmEditPackageOpen, setConfirmEditPackageOpen] = useState(false);
  const [packages, setPackages] = useState([]);
  const [addTime, setAddTime] = useState("");
  const [addPrice, setAddPrice] = useState("");
  const [editPrice, setEditPrice] = useState("");
  const [editTime, setEditTime] = useState("");
  const [editPackageObj, setEditPackageObj] = useState({});
  const [editPackageSentObj, setEditPackageSentObj] = useState({});
  const [deletePackageOpen, setDeletePackageOpen] = useState(false);
  const [packageId, setPackageId] = useState("");
  const [addTimeError, setAddTimeError] = useState(false);
  const [addPriceError, setAddPriceError] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /// useEffects
  useEffect(() => {
    getAllPackagesFunc();
  }, []);

  /// get all packages func
  const getAllPackagesFunc = async () => {
    try {
      let response = await getAllPackagesApi("sponsored");
      let data = await response?.data?.data;
      setPackages([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  /// add Package Func
  const addPackageFunc = async () => {
    if (addPrice == "" || addTime == "") {
      if (addPrice == "") {
        setAddPriceError(true);
      }
      if (addTime == "") {
        setAddTimeError(true);
      }
    } else {
      setLoading(true);
      try {
        let response = await addPackageApi({
          price: addPrice,
          time: addTime * 3600,
          type: "sponsored",
        });
        message.success(response?.data?.message);
        setLoading(false);
        setAddPackageOpen(false);
        getAllPackagesFunc();
        setAddPrice("");
        setAddTime("");
      } catch (error) {
        setLoading(false);
        let errorsArr = [];

        let errors = await error?.response?.data?.errors;
        errors.map((e) => {
          let error = e?.field ? e.field + "  " + e?.message : e?.message;
          errorsArr.push(error);
        });

        message.error(errorsArr.join());
      }
    }
  };

  /// edit package change handler

  const editPackageChange = (e) => {
    let { name, value } = e?.target;
    setEditPackageObj((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    switch (name) {
      case "price":
        setEditPrice(value);
        break;
      case "time":
        setEditTime(value);
        break;

      default:
        break;
    }
    return editPackageObj;
  };

  const editPackageFunc = async () => {
    setConfirmEditPackageOpen(true);
    let obj = {};
    Object.keys(editPackageObj)?.forEach((key) => {
      const value = editPackageObj[key];
      if (value) {
        obj[key] = value;
      }
    });
    setEditPackageSentObj(obj);
  };

  /// confirm edit package
  const confirmEditPackageFunc = async () => {
    setLoading(true);
    try {
      let response = await editPackageApi(packageId, {
        ...editPackageSentObj,
        time: editPackageSentObj.time * 3600,
      });
      message.success(response?.data?.message);
      setLoading(false);
      setConfirmEditPackageOpen(false);
      setEditPackageOpen(false);
      setEditPackageObj({});
      setEditPackageSentObj({});
      getAllPackagesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  /// delete package func
  const deletePackageFunc = async () => {
    setLoading(true);
    try {
      let response = await deletePackageApi(packageId);
      message.success(response?.data?.message);
      setLoading(false);
      setDeletePackageOpen(false);
      getAllPackagesFunc();
    } catch (error) {
      setLoading(false);
      let errorsArr = [];

      let errors = await error?.response?.data?.errors;
      errors.map((e) => {
        let error = e?.field ? e.field + "  " + e?.message : e?.message;
        errorsArr.push(error);
      });

      message.error(errorsArr.join());
    }
  };

  return (
    <>
      <div
        className='sec-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <p className='pack-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الباقات</span>
          </p>
          <button
            className='pack-add-pack-btn'
            onClick={() => {
              setAddPackageOpen(true);
            }}
          >
            إضافة باقة جديدة
          </button>
        </div>
        <div className='pack-packages-div'>
          {packages?.map((pack) => (
            <div className='pack-packages-card'>
              <Row key={pack?.id}>
                <Col xs={12} style={{ fontSize: "20px" }}>
                  {formatSecondsToHoursArabic(pack?.time)}
                </Col>
                <Col xs={12}>
                  <div className='pack-icons-div'>
                    <span
                      className='pack-table-edit'
                      onClick={() => {
                        setEditPackageOpen(true);
                        setPackageId(pack?.id);
                        setEditPrice(pack?.price);
                        setEditTime(pack?.time / 3600);
                      }}
                    >
                      <BiEditAlt style={{ fontSize: "20px" }} />
                    </span>
                    <span
                      className='pack-table-delete'
                      onClick={() => {
                        setDeletePackageOpen(true);
                        setPackageId(pack?.id);
                      }}
                    >
                      <FaRegTrashAlt style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }} gutter={20}>
                <Col xs={4}>
                  <span className='pack-wallet-icon-span'>
                    <BiWalletAlt className='pack-wallet-icon' />
                  </span>
                </Col>
                <Col xs={20} className='pack-card-price'>
                  {pack?.price} ريال
                </Col>
              </Row>
              <Row style={{ marginTop: "10px" }} gutter={20}>
                <Col xs={2}>
                  <AiFillCopy
                    onClick={() => {
                      navigator.clipboard.writeText(pack?.id);
                      message.success("تم نسخ الرمز بنجاح");
                    }}
                    className='pack-wallet-icon cursor-pointer'
                  />
                </Col>
                <Col xs={20} style={{ color: "rgba(151, 151, 151, 1)" }}>
                  Product ID: {pack?.id}
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </div>
      {/***** add new package******/}
      <Modal
        title='إضافة باقة جديدة'
        className='pack-add-section-modal'
        open={addPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setAddPackageOpen(false);
          setAddPrice("");
          setAddTime("");
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <input
            type='number'
            className='pack-add-pack-modal-input'
            placeholder='عدد ساعات الباقة'
            value={addTime}
            onChange={(e) => {
              setAddTime(e.target.value);
              if (e.target.value !== "") {
                setAddTimeError(false);
              }
            }}
          />
          {addTimeError && (
            <p style={{ color: "red" }}>يجب ادخال عدد ساعات الباقة</p>
          )}
          <input
            type='number'
            className='pack-add-pack-modal-input'
            placeholder='سعر الباقة'
            style={{ marginTop: "15px" }}
            value={addPrice}
            onChange={(e) => {
              setAddPrice(e.target.value);
              if (e.target.value !== "") {
                setAddPriceError(false);
              }
            }}
          />
          {addPriceError && (
            <p style={{ color: "red" }}>يجب ادخال سعر الباقة</p>
          )}
          <Button
            className='pack-add-pack-modal-btn'
            onClick={addPackageFunc}
            loading={loading}
          >
            إضافة
          </Button>
        </div>
      </Modal>

      {/***** edit package******/}
      <Modal
        title='تعديل الباقة'
        className='pack-add-section-modal'
        open={editPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setEditPackageOpen(false);
          setEditPackageObj({});
          setEditPackageSentObj({});
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <label className='w-full text-right mb-3 mt-4'>
            عدد ساعات الباقة{" "}
          </label>
          <input
            type='number'
            name='time'
            className='pack-add-pack-modal-input'
            placeholder='عدد ساعات الباقة'
            value={editTime}
            onChange={editPackageChange}
          />
          <label className='w-full text-right mb-3 mt-4'>سعر الباقة </label>
          <input
            type='number'
            name='price'
            className='pack-add-pack-modal-input'
            placeholder='سعر الباقة'
            value={editPrice}
            onChange={editPackageChange}
          />
          <label className='w-full text-right mb-3 mt-4'>ID المنتج</label>
          <div className='flex items-center w-full gap-2 '>
            <AiFillCopy
              className='size-5 cursor-pointer'
              onClick={() => {
                navigator.clipboard.writeText(packageId);
                message.success("تم نسخ الرمز بنجاح");
              }}
            />
            <input
              disabled={true}
              name='id'
              className='pack-add-pack-modal-input text-gray-400'
              placeholder='package id'
              value={packageId}
            />
          </div>
          <div className='pack-edit-pack-modal-btns'>
            <button
              className='pack-edit-pack-modal-okBtn'
              onClick={editPackageFunc}
            >
              تعديل
            </button>
            <button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setEditPackageOpen(false);
                setEditPackageObj({});
                setEditPackageSentObj({});
              }}
            >
              إلغاء
            </button>
          </div>
        </div>
      </Modal>
      {/***** confirm edit package******/}
      <Modal
        title='تأكيد تعديل الباقة'
        className='pack-add-section-modal'
        open={confirmEditPackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setConfirmEditPackageOpen(false);
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <div className='pack-edit-pack-modal-btns'>
            <Button
              className='pack-edit-pack-modal-okBtn'
              onClick={confirmEditPackageFunc}
              loading={loading}
            >
              تأكيد
            </Button>
            <Button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setConfirmEditPackageOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
      {/***** delete package******/}
      <Modal
        title=' حذف الباقة'
        className='pack-add-section-modal'
        open={deletePackageOpen}
        onOk={() => {}}
        onCancel={() => {
          setDeletePackageOpen(false);
        }}
        footer={null}
      >
        <div className='pack-add-pack-modal-body'>
          <div className='pack-edit-pack-modal-btns'>
            <Button
              className='pack-edit-pack-modal-okBtn'
              onClick={deletePackageFunc}
              loading={loading}
            >
              حذف
            </Button>
            <Button
              className='pack-edit-pack-modal-cancelBtn'
              onClick={() => {
                setDeletePackageOpen(false);
              }}
            >
              إلغاء
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
