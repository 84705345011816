import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useGlobalContextState } from "../../context/GlobalContext";

function ProtectedRoutes({ path, redirectPath, children }) {
  const location = useLocation();
  const GlobalContextState = useGlobalContextState();
  const redirectTo = redirectPath ? redirectPath : `/login`;

  const isAllowed = GlobalContextState.roleTabs.some(
    (tab) => tab.path === path
  );

  if (!isAllowed) {
    return <Navigate to={redirectTo} replace state={{ from: location }} />;
  } else {
    return children ? children : <Outlet />;
  }
}

export default ProtectedRoutes;
