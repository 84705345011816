import newRequest from "../utils/newRequest";

/// add instruction
export const addInstructionApi = (data) => {
  let result = newRequest.post("/instruction", data);
  return result;
};

// add condition
export const addConditionApi = (data) => {
  let result = newRequest.put("/instruction", data);
  return result;
};

// get all instructions
export const getAllInstructionsApi = () => {
  let result = newRequest.get("/instruction");
  return result;
};

// get instruction by id
export const getInstructionByIdApi = (id) => {
  let result = newRequest.get(`/instruction/${id}`);
  return result;
};

// update instruction
export const updateInstructionApi = (data) => {
  let result = newRequest.put(`/instruction/`, data);
  return result;
};

// delete instruction
export const deleteInstructionApi = (data) => {
  let result = newRequest.delete(`/instruction/`, { data: data });
  return result;
};
