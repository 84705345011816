import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContext } from "./context/GlobalContext";
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //<React.StrictMode>
  <BrowserRouter>
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <GlobalContext>
          <App />
        </GlobalContext>
      </HelmetProvider>
      <ReactQueryDevtools initialIsOpen={false} position="left" />
    </QueryClientProvider>
  </BrowserRouter>
  //</React.StrictMode>
);

reportWebVitals();
