import "./Invoice.css";
import { Row, Col, Modal } from "antd";
import { getAllInvoicesApi } from "../../APIs/InvoiceApis";
import { useNavigate } from "react-router-dom";
import { FaRegTrashAlt } from "react-icons/fa";
import { useEffect, useState } from "react";

export const Invoice = () => {
  const [invoices, setInvoices] = useState([]);
  const navigate = useNavigate();
  ///useEffects
  useEffect(() => {
    getAllInvoicesFunc();
  }, []);

  ///get invoices func
  const getAllInvoicesFunc = async () => {
    try {
      let response = await getAllInvoicesApi();
      let data = await response?.data?.data;
      setInvoices([...data]);
    } catch (error) {
      if (error.response.status === 401) {
        localStorage.removeItem("user");
        navigate("/login");
      }
    }
  };

  return (
    <>
      <div
        className='inv-main'
        style={{
          backgroundColor: "whitesmoke",
          width: "100%",
          padding: "15px",
        }}
      >
        <div className='inv-title-div'>
          <p className='sec-top-txt'>
            <span style={{ color: "rgba(151, 151, 151, 1)" }}>
              لوحة التحكم /
            </span>{" "}
            <span>الفاتورة</span>
          </p>
        </div>
        <div className='inv-table'>
          <div style={{ minWidth: "500px" }}>
            <Row className='inv-table-head-row'>
              <Col xs={4} className='inv-table-head-col'>
                #
              </Col>
              <Col xs={10} className='inv-table-head-col'>
                اسم المستخدم
              </Col>
              <Col xs={4} className='inv-table-head-col'>
                المدة / الكمية
              </Col>
              <Col xs={4} className='inv-table-head-col'>
                النوع
              </Col>
              {/* <Col xs={2}></Col> */}
            </Row>
          </div>
          <div
            style={{ minWidth: "500px", maxHeight: "400px", overflow: "auto" }}
          >
            {invoices?.map((invoice, index) => (
              <Row className='inv-table-row' key={index}>
                <Col xs={4} className='inv-table-col'>
                  {index + 1}
                </Col>
                <Col xs={10} className='inv-table-col'>
                  {invoice?.userId?.userName ? invoice?.userId?.userName : "ــ"}
                </Col>
                <Col xs={4} className='inv-table-col'>
                  باقة{" "}
                  {invoice.time
                    ? `${(invoice?.time / 3600).toFixed(2)} ساعة`
                    : `${invoice.rocketsCount} صاروخ`}
                </Col>
                <Col xs={4} className='inv-table-col'>
                  {invoice.time ? `أسألة مثبتة` : `صواريخ`}
                </Col>
                {/* <Col xs={2}>
                  <div
                    
                    className='inv-table-delete-div'
                  >
                   
                    <span className="inv-table-delete" onClick={() => {}}>
                      <FaRegTrashAlt style={{ fontSize: "18px" }} />
                    </span>
                  </div>
                </Col> */}
              </Row>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
